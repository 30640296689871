import { ProductValue, Value } from "../../types";

export enum CurrentActionTypes {
  UPDATE = "updatecurrent",
  CREATE = "createcurrent",
  CLEAR = "clearcurrent",
  PRICE = "setprice",
}

export const updateProduct =
  (val: Value, id: string) =>
  (
    dispatch: (a: {
      type: CurrentActionTypes;
      payload: Value;
      meta: any;
    }) => void
  ) => {
    dispatch({
      type: CurrentActionTypes.UPDATE,
      payload: val,
      meta: { id: id },
    });
  };

export const setProductPrice =
  (price: number) =>
  (dispatch: (a: { type: CurrentActionTypes; payload: number }) => void) => {
    dispatch({
      type: CurrentActionTypes.PRICE,
      payload: price,
    });
  };

export const clearProduct =
  () =>
  (dispatch: (a: { type: CurrentActionTypes; payload: string }) => void) => {
    dispatch({
      type: CurrentActionTypes.CLEAR,
      payload: "",
    });
  };

export const createProduct =
  (product: ProductValue) =>
  (
    dispatch: (a: { type: CurrentActionTypes; payload: ProductValue }) => void
  ) => {
    dispatch({
      type: CurrentActionTypes.CREATE,
      payload: product,
    });
  };
