import styled from "styled-components";

export const PatternStyle = styled.div`
padding-bottom:20px;
`;

export const ColorStyle = styled.div`
  display: grid;
  width: 80%;
  grid-template-columns: 50px 1fr;
  align-items:center;
  gap: 30px;
  margin: 15px auto;
  label{
    font-size: 18px;
  }
`;
