import React, { useState } from "react";
import styled from "styled-components";
import { P, ThemeAccentColor } from "../assets/style/basic";

const Banner: React.FC<{ message: string }> = ({ message }) => {

  const [open, setOpen] = useState(true);

  return (
    <BannerStyle isOpen={open}>
      <button onClick={()=>setOpen(false)}>x</button>
      <P dangerouslySetInnerHTML={{ __html: message }}></P>
    </BannerStyle>
  );
};

export default Banner;

let BannerStyle = styled.div`
  margin: 0;
  background-color: ${ThemeAccentColor};
  color:white;
  z-index:100;
  position:relative;
  max-height: ${(p:{isOpen:boolean}) => p.isOpen? "500px" : "0px"};
  transition: max-height 0.5s linear;
  overflow:auto;
  button {
    position:absolute;
    left: 30px;
    top: 25px;
  }
  p {
      text-align:center;
      margin: 0;
      padding: 25px 100px 25px 50px;
  }
  a {
      color:white;
      text-decoration: underline;
  }
`;
