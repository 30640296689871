export enum NotificationActionTypes {
  ADD = "addnotification",
  DEL = "deletenotification",
  CLEAR = "clearnotifications",
}

export const sendNotification =
  (message: string) =>
  (
    dispatch: (a: { type: NotificationActionTypes; payload: string }) => void
  ) => {
    dispatch({
      type: NotificationActionTypes.ADD,
      payload: message,
    });
  };

export const purgeNotifications =
  (index: number) =>
  (dispatch: (a: { type: NotificationActionTypes }) => void) => {
    dispatch({
      type: NotificationActionTypes.DEL,
    });
  };

export const clearNotifications =
  () => (dispatch: (a: { type: NotificationActionTypes }) => void) => {
    dispatch({
      type: NotificationActionTypes.CLEAR,
    });
  };
