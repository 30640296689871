import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, H2 } from "../../assets/style/basic";
import CustomColor from "../../components/fields/CustomColor";
import CustomLettering from "../../components/fields/CustomLettering";
import Radio from "../../components/fields/Radio";
import Textbox from "../../components/fields/Textbox";
import {
  ColorField,
  FieldTypes,
  hash,
  LetteringField,
  Product,
  ProductValue,
  RadioField,
  SelectField,
  TextboxField,
} from "../../types";
import { StoreState } from "../../redux/store";
import { createProduct } from "../../redux/actions/currentActions";
import ProductSummery from "../../components/fields/ProductSummery";
import {
  CustomProductPageStyle,
  ProductOptions,
} from "../../assets/style/ProductPage";
import { ProductSummeryFloatContainer } from "../../assets/style/ProductSummeryStyle";
import CustomSelect from "../../components/fields/CustomSelect";
import CustomLetteringNew from "../../components/fields/CustomLetteringNew";

let connector = connect(
  (state: StoreState) => ({
    current: state.current,
  }),
  { createProduct }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
  product: Product;
  addToCartHandler: (a:ProductValue)=>void;
}

const CustomProduct: React.FC<ReduxProps & Props> = ({
  createProduct,
  current,
  product,
  addToCartHandler,
}) => {
  const [renderedComp, setRenderedComp] = useState<JSX.Element>();

  useEffect(() => {
    // Prevent searching for product every time we re-render
    // Prevent infinite re-renders
    if (renderedComp) return;

    // If we find  product, render the fields and create a current object in redux
    const [renderedFields, productValue] = renderFieldComponents(product);
    setRenderedComp(renderedFields);

    // If our previous current is the same product, display that data instead.
    if (current.id === product.details.id) return;
    if (product.canStack) {
      createProduct({
        price: product.details.price.starting,
        id: product.details.id,
        values: productValue,
        quantity: 1,
      });
    } else {
      createProduct({
        price: product.details.price.starting,
        id: product.details.id,
        values: productValue,
        quantity: 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, renderedComp]);

  // Once a user submits, add order to the cart
  function handleSubmit() {
    // Validate user inputs
    for (const field of product.fields!) {
      let currentForm = document.getElementsByName(
        field.id
      )[0] as HTMLFormElement;
      if (!currentForm.reportValidity()) return;
    }
    // Reset rendered components (TODO: prevent this re-render from happening)
    setRenderedComp(undefined);
    // Add item to cart and confirm
    addToCartHandler(current);
  }
  return (
    <CustomProductPageStyle>
      <div>
        <ProductSummeryFloatContainer className="product-info">
          <ProductSummery product={product} />
          <Button onClick={handleSubmit}> Submit Order </Button>
        </ProductSummeryFloatContainer>
      </div>
      <ProductOptions>{renderedComp}</ProductOptions>
    </CustomProductPageStyle>
  );
};

export default connector(CustomProduct);

// Returns a JSX obj of all the fields for the product and the value object
function renderFieldComponents(item: Product): [a: JSX.Element, b: hash<any>] {

  if (!item.fields)
    throw new Error("tried to create fields when fields didn't exist!");
  let value: hash<any> = {};
  let result = (
    <>
      {item.fields.map((field) => {
        value[field.id] = field.field.value;
        switch (field.type) {
          case FieldTypes.RADIO:
            let radioField = field.field as RadioField;
            return (
              <form name={field.id} key={field.id}>
                <H2>{field.name}</H2>
                <Radio
                  isRequired={true}
                  id={field.id}
                  name={field.name}
                  radio={radioField.options}
                  type={radioField.type}
                />
              </form>
            );
          case FieldTypes.COLOR:
            let colorField = field.field as ColorField;
            return (
              <form name={field.id} key={field.id}>
                <CustomColor
                  isRequired={true}
                  id={field.id}
                  value={colorField.value}
                  patterns={colorField.patterns}
                  colors={colorField.colors}
                />
              </form>
            );
          case FieldTypes.LETTERING:
            let letteringField = field.field as LetteringField;
            return (
              <CustomLetteringNew
                key={field.id}
                name={field.name}
                options={letteringField.options}
                logos={letteringField.logos}
                fonts={letteringField.fonts}
                colors={letteringField.colors}
                id={field.id}
              />
            );

          case FieldTypes.TEXT:
            let textField = field.field as TextboxField;
            return (
              <form name={field.id} key={field.id}>
                <H2>{field.name}</H2>
                <Textbox
                  value={textField.value}
                  description={field.description || ""}
                  id={field.id}
                />
              </form>
            );
          case FieldTypes.SELECT:
            let selectField = field.field as SelectField;
            return (
              <form name={field.id} key={field.id}>
                <CustomSelect
                  name={field.name}
                  options={selectField.details}
                  isRequired={selectField.details.required}
                  id={field.id}
                />
              </form>
            );
          default:
            throw new Error("Unknown field type!");
        }
      })}
    </>
  );
  return [result, value];
}
