import React from "react";
import { NavLink } from "react-router-dom";
import { FooterStyle } from "../assets/style/basic";
import { Nav } from "../assets/style/header";
import insta from "../assets/img/instagram.svg"
import fb from "../assets/img/facebook.svg"

const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-moto">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt.
        </p>
      </div>
      <div className="footer-nav">
        <Nav style={{ backgroundColor: "transparent" }} open={true} collapse={false}>
          <li>
            <NavLink to="/"> Home </NavLink>
          </li>
          <li>
            <NavLink to="/about"> About </NavLink>
          </li>
          <li>
            <NavLink to="/products"> Products </NavLink>
          </li>
          <li>
            <NavLink to="/events"> Events </NavLink>
          </li>
          <li>
            <NavLink to="/contact"> Contact </NavLink>
          </li>
        </Nav>
      </div>
      <div className="footer-icons">
          <img src={insta} alt="Instagram logo" />
          <img src={fb} alt="Facebook logo" />
      </div>
      <div className="footer-cr"> &copy; Cottageaccents 2021</div>
      <div className="footer-credit">Made with ❤️ by Andrew <br /> <br /> Software acknowledgments</div>
    </FooterStyle>
  );
};

export default Footer;
