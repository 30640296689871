import { H1, InputWithLabel, P } from "../assets/style/basic";
import { AboutSection, CallToAction, PhotoGrid } from "../assets/style/home";
import { EventCard } from "../components/cards/EventCard";
import FeaturedCard from "../components/cards/Featured";
import Slider, { Settings } from "react-slick";
import Slideshow from "../components/Slideshow";
import { StoreState } from "../redux/store";
import { connect, ConnectedProps } from "react-redux";
import { FieldTypes, Product, ProductTypes, RadioTypes } from "../types";

let connector = connect(
  (state: StoreState) => ({
    products: state.products,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Home: React.FC<ReduxProps> = ({ products }) => {
  console.log(JSON.stringify(testing))
  var settings: Settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <H1> Featured Products </H1>
      <Slider {...settings}>
        {products.reduce((elements: JSX.Element[], current) => {
          if (current.details.isFeatured) {
            elements.push(
              <FeaturedCard
                key={current.details.id}
                id={current.details.id}
                isLarge={false}
                description={current.details.description.short}
                canCustomize={false}
                price={current.details.price.starting}
                name={current.details.name}
              />
            );
          }
          return elements;
        }, [])}
      </Slider>
      <H1>About Us</H1>
      <AboutSection>
        <div>
          <P>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            vestibulum volutpat odio, quis sagittis nibh pretium vel.
            Suspendisse potenti. Donec ac rhoncus quam, eu tempus ligula. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare
            lectus non neque consectetur lacinia.
          </P>
        </div>
        <div>
          <Slideshow delay={10}>
            <img
              src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
              alt=""
            />
            <img
              src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
              alt=""
            />
            <img
              src="https://www.readree.com/wp-content/uploads/2021/01/Search-Engines-That-Search-for-Duplicate-images.jpg"
              alt=""
            />
          </Slideshow>
        </div>
      </AboutSection>
      <CallToAction>
        <H1>Keep up with us</H1>
        <div>
          <P>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            vestibulum volutpat odio, quis sagittis nibh pretium vel.
          </P>
          <div>
            <InputWithLabel>
              <label htmlFor="email-field">Email</label>
              <input type="text" name="email-field" />
            </InputWithLabel>
            <input type="submit" value="Sign Up" />
          </div>
        </div>
      </CallToAction>
      <H1> Upcoming Shows </H1>
      <Slider {...settings}>
        <EventCard item="1" />
        <EventCard item="2" />
        <EventCard item="3" />
        <EventCard item="4" />
        <EventCard item="5" />
      </Slider>
      <H1> Photos </H1>
      <PhotoGrid>
        <img
          src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
          alt=""
        />
        <img
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
          alt=""
        />
        <img
          src="https://www.readree.com/wp-content/uploads/2021/01/Search-Engines-That-Search-for-Duplicate-images.jpg"
          alt=""
        />
        <img
          src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
          alt=""
        />
        <img
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
          alt=""
        />
        <img
          src="https://www.readree.com/wp-content/uploads/2021/01/Search-Engines-That-Search-for-Duplicate-images.jpg"
          alt=""
        />
        <img
          src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
          alt=""
        />
        <img
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
          alt=""
        />
        <img
          src="https://www.readree.com/wp-content/uploads/2021/01/Search-Engines-That-Search-for-Duplicate-images.jpg"
          alt=""
        />
      </PhotoGrid>
    </div>
  );
};

export default connector(Home);

const testing: Product = {
  type: ProductTypes.CUSTOM,
  canStack: false,
  imgs: ["test"],
  details: {
    id: "12222",
    name: "boat",
    category: "Hello",
    isFeatured: true,
    price: {
      isBase: false,
      starting: 100,
    },
    description: {
      short: "short.",
      full: "this is full.",
    },
  },
  fields: [
    {
      type: FieldTypes.RADIO,
      id: "size",
      name: "Size",
      field: {
        type: RadioTypes.BAR,
        value: "",
        options: [
          {
            id: "sml",
            name: "24 Inch",
            price: 100,
          },
          {
            id: "med",
            name: "32 Inch",
            price: 200,
          },
          {
            id: "lrg",
            name: "44 Inch",
            price: 300,
          },
        ],
      },
    },
    {
      type: FieldTypes.COLOR,
      id: "paint",
      name: "Paint Color",
      field: {
        patterns: [
          {
            id: "solid",
            name: "Solid",
            img: "https://via.placeholder.com/150",
            layouts: [
              {
                name: "Body",
                id: "body",
              },
            ],
          },
          {
            id: "3tone",
            name: "3 Tone",
            img: "https://via.placeholder.com/150",
            layouts: [
              {
                name: "Top",
                id: "top",
              },
              {
                name: "Middle",
                id: "mid",
              },
              {
                name: "Bottom",
                id: "bot",
              },
            ],
          },
        ],
        colors: [
          {
            name: "Chili Pepper",
            color: "#b14040",
            id: "red-1",
          },
          {
            name: "Clearest Ocean Blue",
            color: "#3195ca",
            id: "blue-1",
          },
          {
            name: "Cactus Green",
            color: "#227442",
            id: "green-1",
          },
        ],
        value: {
          pattern: "",
          color: {},
        },
      },
    },
    {
      type: FieldTypes.LETTERING,
      id: "lettering",
      name: "Lettering",
      field: {
        options: {
          Right: [
            {
              name: "Mid",
              id: "mid",
              img: "https://cottageaccents.files.wordpress.com/2015/05/sam_0426.jpg?w=4000&h=",
              fields: [
                {
                  name: "Center",
                  id: "center",
                },
              ],
              hasLogo: false,
              price: 30,
            },
            {
              name: "Front",
              id: "front",
              img: "https://cottageaccents.files.wordpress.com/2015/05/dsc00177.jpg?w=4000&h=",
              fields: [
                {
                  name: "Front",
                  id: "front",
                },
                {
                  name: "Center",
                  id: "center",
                },
              ],
              hasLogo: true,
              price: 70,
            },
          ],
          Left: [
            {
              name: "Mid",
              id: "mid",
              img: "https://cottageaccents.files.wordpress.com/2015/05/sam_0426.jpg?w=4000&h=",
              fields: [
                {
                  name: "Center",
                  id: "center",
                },
              ],
              hasLogo: false,
              price: 30,
            },
            {
              name: "Front",
              id: "front",
              img: "https://cottageaccents.files.wordpress.com/2015/05/dsc00177.jpg?w=4000&h=",
              fields: [
                {
                  name: "Front",
                  id: "front",
                },
                {
                  name: "Center",
                  id: "center",
                },
              ],
              hasLogo: true,
              price: 70,
            },
          ],
        },
        value: {
          Left: {
            layout: "",
            color: "",
            font: "",
            text: {},
          },
          Right: {
            layout: "",
            color: "",
            font: "",
            text: {},
          },
        },
        colors: [
          {
            name: "Chili Pepper",
            color: "#b14040",
            id: "red-1",
          },
          {
            name: "Clearest Ocean Blue",
            color: "#3195ca",
            id: "blue-1",
          },
          {
            name: "Cactus Green",
            color: "#227442",
            id: "green-1",
          },
        ],
        logos: [
          {
            name: "Compass Rose",
            id: "compass",
            img: "https://via.placeholder.com/150",
          },
        ],
        fonts: [
          {
            name: "Roboto",
            id: "roboto",
          },
        ],
      },
    },
  ],
};
