import React, { useEffect, useRef, useState } from "react";
import {
  ColorOption,
  ColorOptionSelected,
  CustomSelectContainer,
  ImgOption,
  ImgOptionSelected,
  SelectHeader,
  SelectOptions,
  SimpleOption,
  TextOption,
  TextOptionSelected,
} from "../../assets/style/fields/CustomLetteringStyle";
import DownArrow from "../../assets/img/down_arrow_large.svg";
import UpArrow from "../../assets/img/up_arrow_large.svg";
import {
  ColorSelectData,
  ImgSelectData,
  SelectDetails,
  SelectTypes,
} from "../../types";

type Props = {
  options: SelectDetails;
  onChange: (id: string) => void;
  value: string;
  disabled?: boolean;
  isRequired?: boolean;
  isCompleted?: boolean;
};

const CustomSelectInternal: React.FC<Props> = ({
  options,
  onChange,
  value,
  disabled,
  isRequired,
  isCompleted,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(
    options.data.findIndex((item) => item.id === value)
  );
  const menuRef = useRef<HTMLDivElement>(null);

  console.log(selected, value);


  // Close the menu if someone clicks out of it
  function handleClick(e: MouseEvent) {
    if (!(menuRef && menuRef.current?.contains(e.target as Node)))
      setOpen(false);
  }

  useEffect(() => {
    // Mount or un-mount the menu close event
    if (open) {
      document.addEventListener("click", handleClick);
    }

    setSelected(options.data.findIndex((item) => item.id === value));

    return () => {
      document.removeEventListener("click", handleClick);
    };
  },[open, options.data, value]);

  // When an option is selected, report what option was selected and close the menu
  function handleOptionSelect(index: number, id: string) {
    setOpen(false);
    setSelected(index);
    onChange(id);
  }

  // Render the options based on the type of select
  let renderedOptions = options.data.map((item, index) => {
    switch (options.type) {
      case SelectTypes.IMG:
        return (
          <ImgOption
            onClick={() => handleOptionSelect(index, item.id)}
            key={item.id + index}
            size={200}
          >
            <img src={(item as any).img} alt={item.name} />
            <h3>
              {item.name}
              {item.price && " - $" + item.price}
            </h3>
          </ImgOption>
        );
      case SelectTypes.COLOR:
        return (
          <ColorOption
            onClick={() => handleOptionSelect(index, item.id)}
            color={(item as any).color}
            key={item.id + index}
          >
            <div></div>
            <h3>{item.name}</h3>
          </ColorOption>
        );
      case SelectTypes.TEXT:
        return (
          <TextOption
            onClick={() => handleOptionSelect(index, item.id)}
            font={(item as any).font}
            key={item.id + index}
          >
            {item.name}
            {item.price && " - $" + item.price}
          </TextOption>
        );
      default:
        throw new Error("Unknown type!");
    }
  });

  // If not required, give an option to clear the selected item
  if (!isRequired) {
    renderedOptions.unshift(
      <SimpleOption key="clear" onClick={() => handleOptionSelect(-1, "")}>
        Clear
      </SimpleOption>
    );
  }

  function renderSelected() {
    // When nothing is selected
    if (selected === -1 && isRequired) return <div>Select an option.</div>;
    if (selected === -1 && !isRequired) return <div>Nothing selected.</div>;

    // If something is selected, display it based on what type of select it is
    switch (options.type) {
      case SelectTypes.IMG:
        return (
          <ImgOptionSelected>
            <img
              src={(options.data[selected] as ImgSelectData).img}
              alt={options.data[selected].name}
            />
            <h3>{options.data[selected].name}</h3>
          </ImgOptionSelected>
        );
      case SelectTypes.COLOR:
        return (
          <ColorOptionSelected
            color={(options.data[selected] as ColorSelectData).color}
          >
            <div></div>
            <h3>{options.data[selected].name}</h3>
          </ColorOptionSelected>
        );
      case SelectTypes.TEXT:
        return (
          <TextOptionSelected font={(options.data[selected] as any).font}>
            {options.data[selected].name}
          </TextOptionSelected>
        );
      default:
        throw new Error("Unknown type!");
    }
  }

  // If the item is required, we validate that by including a hidden radio that gets selected when an item is picked
  return (
    <CustomSelectContainer ref={menuRef}>
      <input
        type="radio"
        required={isRequired}
        checked={isCompleted}
        name={options.data[0].id}
      />
      <SelectHeader disabled={!!disabled}>
        <button
          onClick={() => {
            if (disabled) return;
            setOpen(!open);
          }}
          type="button"
        ></button>
        {renderSelected()}
        <div>
          {!open ? (
            <img
              src={DownArrow}
              className="selectArrow"
              alt="Expand selection menu"
            />
          ) : (
            <img
              src={UpArrow}
              className="selectArrow"
              alt="Close selection menu"
            />
          )}
        </div>
      </SelectHeader>
      {open && !disabled && <SelectOptions>{renderedOptions}</SelectOptions>}
    </CustomSelectContainer>
  );
};

export default CustomSelectInternal;
