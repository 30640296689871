import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { TextboxStyle } from '../../assets/style/fields/TextboxStyle'
import { StoreState } from '../../redux/store'
import {updateProduct} from '../../redux/actions/currentActions'

let connector = connect(
    (state:StoreState) => ({
        current:state.current
    }),
    {updateProduct}
);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
    description:string | null;
    id:string;
    value:string;
}

const Textbox: React.FC<Props & ReduxProps> = ({description, id, value, current, updateProduct}) => {

    function handleChange(e:React.ChangeEvent<HTMLTextAreaElement>) {
        updateProduct(e.target.value,id)

    }

    return (
        <TextboxStyle>
            {description && <p>{description}</p>}
            <textarea name={id} value={current.values[id].toString()} placeholder="Enter message here." onChange={handleChange}></textarea>
        </TextboxStyle>
    )
}

export default connector(Textbox)