import React from "react";
import { H3, P } from "../../assets/style/basic";
import { SimpleCard } from "../../assets/style/cards";
import { connect, ConnectedProps } from 'react-redux'
import { StoreState } from '../../redux/store'
import {updateProduct} from '../../redux/actions/currentActions'
import {Price} from "../../assets/style/basic"
import {
  RadioBarStyle,
  RadioCardStyle,
  RadioImgStyle,
} from "../../assets/style/fields/RadioBarStyle";
import { RadioBar, RadioCard, RadioImg, RadioTypes } from "../../types";

let connector = connect(
  (state:StoreState) => ({
      current:state.current
  }),
  {updateProduct}
);

type ReduxProps = ConnectedProps<typeof connector>;

// Component props passed to element
type Props = {
  id: string;
  name: string;
  radio: RadioBar[] | RadioCard[] | RadioImg[];
  type: RadioTypes;
  value?:string;
  disabled?:boolean;
  isRequired?:boolean;
  onChange?:(id:string) => void;
};

const Radio: React.FC<Props & ReduxProps> = ({ id, name, radio, type, value, onChange,  updateProduct, current, disabled, isRequired }) => {

  if (!current) throw new Error("No current product set.")

  let currentRadioValue = current.values[id];

  function handleChange(value:string) {
    updateProduct(value, id);
  }

  // Renders each type of radio through switch pending on its radio.type seen in enum RadioType
  switch (type) {
    case RadioTypes.BAR:
      let radioBar = radio as RadioBar[];
      return (
        <RadioBarStyle>
          {radioBar.map(rad => {
            return (
              <React.Fragment key={rad.id}>
                <input
                  onChange={
                    () => {
                      if (disabled) return;
                      if (onChange)
                        onChange(rad.id)
                      else
                        handleChange(rad.id)
                    }
                  }
                  required={!!isRequired}
                  type="radio"
                  id={rad.id}
                  data-price={rad.price}
                  name={id}
                  value={rad.id}
                  checked={value? value === rad.id :currentRadioValue === rad.id}
                />
                <label htmlFor={rad.id}>
                  <div key={rad.id}>
                    <H3>{rad.name}</H3>
                    {rad.price && <p><Price> ${rad.price}</Price></p>}
                  </div>
                </label>
              </React.Fragment>
            );
          })}
        </RadioBarStyle>
      );

    case RadioTypes.IMG:
      let radioImg = radio as RadioImg[];
      return (
        <RadioImgStyle>
          {radioImg.map( rad => {
            return (
              <div key={rad.id}>
                <input
                  onChange={
                    () => {
                      if (disabled) return;
                      if (onChange)
                        onChange(rad.id)
                      else
                        handleChange(rad.id)
                    }
                  }
                  required={!!isRequired}
                  type="radio"
                  id={rad.id}
                  data-price={rad.price}
                  name={id}
                  value={rad.id}
                  checked={value? value === rad.id :currentRadioValue === rad.id}
                />
                <label htmlFor={rad.id}>
                  <div>
                    <div>
                      <h3>{rad.name}</h3>
                      {rad.price && <p> ${rad.price}+ tax</p>}
                    </div>
                    <img src={rad.img} alt={rad.name} />
                  </div>
                </label>
              </div>
            );
          })}
        </RadioImgStyle>
      );

    case RadioTypes.CARD:
      let radioCard = radio as RadioCard[];
      return (
        <RadioCardStyle>
          {radioCard.map(rad => {
            return (
              <React.Fragment key={rad.id}>
                <input
                  onChange={
                    () => {
                      if (disabled) return;
                      if (onChange)
                        onChange(rad.id)
                      else
                        handleChange(rad.id)
                    }
                  }
                  type="radio"
                  required={!!isRequired}
                  id={rad.id}
                  data-price={rad.price}
                  name={id}
                  value={rad.id}
                  checked={value? value === rad.id :currentRadioValue === rad.id}
                />
                <label htmlFor={rad.id}>
                  <SimpleCard>
                    <div>
                      <img src={rad.img} alt={rad.name} />
                    </div>
                    <div>
                      <H3>{rad.name}</H3>
                      <P>{rad.description}</P>
                      {rad.price && <p> ${rad.price}+ tax</p>}
                    </div>
                  </SimpleCard>
                </label>
              </React.Fragment>
            );
          })}
        </RadioCardStyle>
      );
  }
};

export default connector(Radio);