import { H1 } from "../assets/style/basic";
import { CategoryGrid, ShopFeaturedWrapper } from "../assets/style/shop";
import FeaturedCard from "../components/cards/Featured";
import ProductCard from "../components/cards/ProductCard";
import { StoreState } from "../redux/store";
import { connect, ConnectedProps } from "react-redux";
import React from "react";

interface Hash<T> {
  [key: string]: T;
}

let connector = connect(
  (state: StoreState) => ({
    products: state.products,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Shop: React.FC<ReduxProps> = ({ products }) => {

  let product_by_category: Hash<JSX.Element[]> = {};

  let featured_products: JSX.Element[] = [];

  products.forEach((item) => {
    if (item.details.isFeatured) {
      featured_products.push(
        <FeaturedCard
          id={item.details.id}
          key={item.details.id}
          isLarge={true}
          description={item.details.description.short}
          price={item.details.price.starting}
          canCustomize={!!item.fields}
          name={item.details.name}
        />
      );
      return;
    }
    product_by_category[item.details.category] = [
      ...(product_by_category[item.details.category]
        ? product_by_category[item.details.category]
        : []),
      <ProductCard
        key={item.details.id}
        name={item.details.name}
        description={item.details.description.short}
        id={item.details.id}
      />,
    ];
  });

  let rendered_products: JSX.Element[] = [];

  for (const prods in product_by_category) {
    if (Object.prototype.hasOwnProperty.call(product_by_category, prods)) {
      const element = product_by_category[prods];
      rendered_products.push(
        <React.Fragment key={prods}>
          <H1>{prods}</H1>
          <CategoryGrid>{element}</CategoryGrid>
        </React.Fragment>
      );
    }
  }

  return (
    <div>
      {featured_products.length > 0 && (
        <>
          <H1>Featured Products</H1>
          <ShopFeaturedWrapper>{featured_products}</ShopFeaturedWrapper>
        </>
      )}
      {rendered_products}
    </div>
  );
};

export default connector(Shop);
