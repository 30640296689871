Object.defineProperty(exports, "__esModule", { value: true });
exports.testing = exports.parseValue = exports.ProductTypes = exports.FieldTypes = exports.RadioTypes = exports.SelectTypes = void 0;
// Custom Select Types
// IMG -  select bar with images as selectable items
// TEXT - select with text as the selectable option
// COLOR - select takes a HEX code and makes that a clickable option
var SelectTypes;
(function (SelectTypes) {
    SelectTypes[SelectTypes["IMG"] = 0] = "IMG";
    SelectTypes[SelectTypes["TEXT"] = 1] = "TEXT";
    SelectTypes[SelectTypes["COLOR"] = 2] = "COLOR";
})(SelectTypes = exports.SelectTypes || (exports.SelectTypes = {}));
// Render options for Radio
// BAR - options are connected and only text
// CARD - options are displayed as a card with an image and text
// IMG - options are an image only
var RadioTypes;
(function (RadioTypes) {
    RadioTypes[RadioTypes["BAR"] = 0] = "BAR";
    RadioTypes[RadioTypes["CARD"] = 1] = "CARD";
    RadioTypes[RadioTypes["IMG"] = 2] = "IMG";
})(RadioTypes = exports.RadioTypes || (exports.RadioTypes = {}));
// RADIO - a custom radio field
// COLOR - for selecting a pattern and colors to fit that pattern
// LETTERING - Lettering with different layout and siding options
// TEXT - basic text input (Only use this once right now)
// SELECT - custom version of HTML select with more options available
var FieldTypes;
(function (FieldTypes) {
    FieldTypes[FieldTypes["RADIO"] = 0] = "RADIO";
    FieldTypes[FieldTypes["COLOR"] = 1] = "COLOR";
    FieldTypes[FieldTypes["LETTERING"] = 2] = "LETTERING";
    FieldTypes[FieldTypes["TEXT"] = 3] = "TEXT";
    FieldTypes[FieldTypes["SELECT"] = 4] = "SELECT";
})(FieldTypes = exports.FieldTypes || (exports.FieldTypes = {}));
// BASIC - no customizations
// BASIC_VARIANT - minor customization (like shirt size)
// CUSTOM - fully customizable with many options
var ProductTypes;
(function (ProductTypes) {
    ProductTypes[ProductTypes["BASIC"] = 0] = "BASIC";
    ProductTypes[ProductTypes["BASIC_VARIANT"] = 1] = "BASIC_VARIANT";
    ProductTypes[ProductTypes["CUSTOM"] = 2] = "CUSTOM";
})(ProductTypes = exports.ProductTypes || (exports.ProductTypes = {}));
// Returns a flattened object representing the selected options to be easier to list in cart and receipt
function parseValue(productValues, product) {
    if (!product.fields)
        return {};
    let result = {};
    product.fields.forEach((item) => {
        // the value(from productValues) for the current field (item from product)
        // value => selected data
        // item => detail about the field selected
        let value = productValues.values[item.id];
        if (!value)
            return;
        switch (item.type) {
            // Color field type
            case FieldTypes.COLOR:
                // Cast value to a ColorValue (can do because we know the field type)
                let colorValue = value;
                let colorField = item.field;
                // Find data for the selected pattern
                let selectedPattern = colorField.patterns.find((pattern) => pattern.id === colorValue.pattern);
                if (!selectedPattern)
                    break;
                // Currently the color field has no associated price, so we set it to undefined, may change
                result[item.name + " Pattern"] = {
                    data: selectedPattern.name,
                    price: undefined,
                };
                // DEV NOTE: I'm ok with this nested for because of how finite the set of permutations will be between pattern and colors
                // Given the selected pattern, loop through the color options
                selectedPattern.layouts.forEach((layout) => {
                    // Match color at layout to possible color option
                    colorField.colors.forEach((color) => {
                        // If we find a match between colors, add it to the results
                        if (colorValue.color[layout.id] === color.id)
                            result[item.name + " " + layout.name] = {
                                data: color.name,
                                price: undefined,
                            };
                    });
                });
                break;
            case FieldTypes.LETTERING:
                let letteringField = item.field;
                let letteringValue = value;
                if (objectIsEmpty(letteringValue))
                    break;
                // Selecting from a side
                for (const key in letteringValue) {
                    if (Object.prototype.hasOwnProperty.call(letteringValue, key) &&
                        Object.prototype.hasOwnProperty.call(letteringField.options, key)) {
                        const value = letteringValue[key];
                        const data = letteringField.options[key].find((layout) => layout.id === value.layout);
                        if (!data)
                            break;
                        // Font
                        let font = letteringField.fonts.find((font) => font.id === value.font);
                        if (!font)
                            break;
                        result[item.name + " " + key + " Font"] = {
                            data: font.name,
                            price: data.price,
                        };
                        // Color
                        let color = letteringField.colors.find((color) => color.id === value.color);
                        if (!color)
                            break;
                        result[item.name + " " + key + " Color"] = {
                            data: color.name,
                            price: undefined,
                        };
                        // If we have a logo, list it
                        if (data.hasLogo) {
                            let logo = letteringField.logos.find((logo) => logo.id === value.logo);
                            if (!logo)
                                break;
                            result[item.name + " " + key + " Logo"] = {
                                data: logo.name,
                                price: undefined,
                            };
                        }
                        // Text
                        data.fields.forEach((field) => {
                            let text = value.text[field.id];
                            result[item.name + " " + key + " " + field.name] = {
                                data: text,
                                price: undefined,
                            };
                        });
                    }
                }
                break;
            case FieldTypes.RADIO:
                let selectedRadio = item.field.options.find((option) => option.id === value);
                if (!selectedRadio)
                    break;
                result[item.name] = {
                    data: selectedRadio.name,
                    price: selectedRadio.price,
                };
                break;
            case FieldTypes.SELECT:
                let selectedSelect = item.field.details.data.find((option) => option.id === value);
                if (!selectedSelect)
                    break;
                result[item.name] = {
                    data: selectedSelect.name,
                    price: selectedSelect.price,
                };
                break;
            case FieldTypes.TEXT:
                if (!value || value === "")
                    break;
                result["Other"] = { data: value, price: undefined };
                break;
        }
    });
    return result;
}
exports.parseValue = parseValue;
function objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}
exports.testing = {
    type: ProductTypes.CUSTOM,
    canStack: false,
    imgs: ["test"],
    details: {
        id: "12222",
        name: "boat",
        category: "Hello",
        isFeatured: true,
        price: {
            isBase: false,
            starting: 100,
        },
        description: {
            short: "short.",
            full: "this is full.",
        },
    },
    fields: [
        {
            type: FieldTypes.RADIO,
            id: "size",
            name: "Size",
            field: {
                type: RadioTypes.BAR,
                value: "",
                options: [
                    {
                        id: "sml",
                        name: "24 Inch",
                        price: 100,
                    },
                    {
                        id: "med",
                        name: "32 Inch",
                        price: 200,
                    },
                    {
                        id: "lrg",
                        name: "44 Inch",
                        price: 300,
                    },
                ],
            },
        },
        {
            type: FieldTypes.COLOR,
            id: "paint",
            name: "Paint Color",
            field: {
                patterns: [
                    {
                        id: "solid",
                        name: "Solid",
                        img: "https://via.placeholder.com/150",
                        layouts: [
                            {
                                name: "Body",
                                id: "body",
                            },
                        ],
                    },
                    {
                        id: "3tone",
                        name: "3 Tone",
                        img: "https://via.placeholder.com/150",
                        layouts: [
                            {
                                name: "Top",
                                id: "top",
                            },
                            {
                                name: "Middle",
                                id: "mid",
                            },
                            {
                                name: "Bottom",
                                id: "bot",
                            },
                        ],
                    },
                ],
                colors: [
                    {
                        name: "Chili Pepper",
                        color: "#b14040",
                        id: "red-1",
                    },
                    {
                        name: "Clearest Ocean Blue",
                        color: "#3195ca",
                        id: "blue-1",
                    },
                    {
                        name: "Cactus Green",
                        color: "#227442",
                        id: "green-1",
                    },
                ],
                value: {
                    pattern: "",
                    color: {},
                },
            },
        },
        {
            type: FieldTypes.LETTERING,
            id: "lettering",
            name: "Lettering",
            field: {
                options: {
                    Right: [
                        {
                            name: "Mid",
                            id: "mid",
                            img: "https://cottageaccents.files.wordpress.com/2015/05/sam_0426.jpg?w=4000&h=",
                            fields: [
                                {
                                    name: "Center",
                                    id: "center",
                                },
                            ],
                            hasLogo: false,
                            price: 30,
                        },
                        {
                            name: "Front",
                            id: "front",
                            img: "https://cottageaccents.files.wordpress.com/2015/05/dsc00177.jpg?w=4000&h=",
                            fields: [
                                {
                                    name: "Front",
                                    id: "front",
                                },
                                {
                                    name: "Center",
                                    id: "center",
                                },
                            ],
                            hasLogo: true,
                            price: 70,
                        },
                    ],
                    Left: [
                        {
                            name: "Mid",
                            id: "mid",
                            img: "https://cottageaccents.files.wordpress.com/2015/05/sam_0426.jpg?w=4000&h=",
                            fields: [
                                {
                                    name: "Center",
                                    id: "center",
                                },
                            ],
                            hasLogo: false,
                            price: 30,
                        },
                        {
                            name: "Front",
                            id: "front",
                            img: "https://cottageaccents.files.wordpress.com/2015/05/dsc00177.jpg?w=4000&h=",
                            fields: [
                                {
                                    name: "Front",
                                    id: "front",
                                },
                                {
                                    name: "Center",
                                    id: "center",
                                },
                            ],
                            hasLogo: true,
                            price: 70,
                        },
                    ],
                },
                value: {
                    Left: {
                        layout: "",
                        color: "",
                        font: "",
                        text: {},
                    },
                    Right: {
                        layout: "",
                        color: "",
                        font: "",
                        text: {},
                    },
                },
                colors: [
                    {
                        name: "Chili Pepper",
                        color: "#b14040",
                        id: "red-1",
                    },
                    {
                        name: "Clearest Ocean Blue",
                        color: "#3195ca",
                        id: "blue-1",
                    },
                    {
                        name: "Cactus Green",
                        color: "#227442",
                        id: "green-1",
                    },
                ],
                logos: [
                    {
                        name: "Compass Rose",
                        id: "compass",
                        img: "https://via.placeholder.com/150",
                    },
                ],
                fonts: [
                    {
                        name: "Roboto",
                        id: "roboto",
                    },
                ],
            },
        },
    ],
};
