import { ProductValue } from "../../types";
import { CurrentActionTypes } from "./currentActions";
import { NotificationActionTypes } from "./notificationActions";

export enum CartActionTypes {
  ADD = "addtocart",
  DEL = "deletefroncart",
}

export const addProductToCart =
  (prod: ProductValue) =>
  (
    dispatch: (a: {
      type: CartActionTypes | CurrentActionTypes | NotificationActionTypes;
      payload: ProductValue | string;
    }) => void
  ) => {
    dispatch({
      type: CartActionTypes.ADD,
      payload: prod,
    });
    dispatch({
      type: CurrentActionTypes.CLEAR,
      payload: "",
    });
    dispatch({
      type: NotificationActionTypes.ADD,
      payload: "Item added to cart.",
    });
  };

export const deleteProductFromCart =
  (id: number) =>
  (
    dispatch: (a: {
      type: CartActionTypes | NotificationActionTypes;
      payload: number | string;
    }) => void
  ) => {
    dispatch({
      type: CartActionTypes.DEL,
      payload: id,
    });
    dispatch({
      type: NotificationActionTypes.ADD,
      payload: "Item removed from cart.",
    });
  };
