import React from "react";
import { useHistory } from "react-router-dom";
import { H2, P, Button } from "../../assets/style/basic";
import { BasicProduct } from "../../assets/style/cards";

interface Props {
  name: string;
  id: string;
  description: string;
}
const ProductCard: React.FC<Props> = ({ name, id, description }) => {

    const history = useHistory();

  function handleClick() {
    history.push("/products/" + id);
  }

  return (
    <BasicProduct>
      <img
        src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
        alt=""
      />
      <div>
        <H2>{name}</H2>
        <P>{description}</P>
        <Button onClick={handleClick}>Details</Button>
      </div>
    </BasicProduct>
  );
};

export default ProductCard;
