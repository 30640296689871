import React, { useState } from "react";
import { H2 } from "../../assets/style/basic";
import { ColorStyle, PatternStyle } from "../../assets/style/fields/CustomColor";
import CustomSelectInternal from "./CustomSelectInternal";
import Radio from "./Radio";
import {ColorPattern, Color, RadioTypes, ColorValue, SelectTypes } from "../../types";
import { connect, ConnectedProps } from 'react-redux'
import { StoreState } from '../../redux/store'
import {updateProduct} from '../../redux/actions/currentActions'

let connector = connect(
  (state:StoreState) => ({
      current:state.current
  }),
  {updateProduct}
);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  patterns: ColorPattern[];
  colors: Color[];
  id:string;
  isRequired?:boolean;
  value:{pattern: string;
    color: {
        [id: string]: string;
    };}
};

const CustomColor: React.FC<Props & ReduxProps> = ({ patterns, colors, value, id, current, updateProduct, isRequired }) => {

  let currentFieldData = current.values[id] as ColorValue;

  const [currentPattern, setCurrentPattern] = useState<number>(patterns.findIndex(item => item.id === currentFieldData.pattern));

  function patternChangeHandler(value: string) {
    updateProduct({color:{}, pattern:value},id);
    setCurrentPattern(patterns.findIndex(item => item.id === value))
  }
  function handleColorChange(this:string, value:string) {
    updateProduct({...currentFieldData, color:{...currentFieldData.color, [this]:value}},id);
  }

  return (
    <div>
      <H2>Pattern</H2>
      <PatternStyle>
        <Radio
          id="pattern"
          name="Pattern"
          isRequired={isRequired}
          onChange={patternChangeHandler}
          type={RadioTypes.IMG}
          value={currentFieldData.pattern}
          radio={
            patterns.map((item) => {
              return {
                name: item.name,
                id: item.id,
                img: item.img,
              };
            })
          }
        />
      </PatternStyle>
      <H2>Color</H2>
      {currentPattern > -1 ?
        patterns[currentPattern].layouts.map((item, index) => {
          return (
            <ColorStyle key={item.name}>
              <label>{item.name}</label>
              <CustomSelectInternal
                isRequired={isRequired}
                isCompleted={!!currentFieldData.color[item.id]}
                onChange={handleColorChange.bind(item.id)}
                value={currentFieldData.color[item.id] || ""}
                options={{
                  type: SelectTypes.COLOR,
                  required: true,
                  data: colors,
                }}
              />
            </ColorStyle>
          );
        }):
        <h3 style={{textAlign:'center', paddingTop:20}}>Please select a pattern above!</h3>}
    </div>
  );
};

export default connector(CustomColor);
