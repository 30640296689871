import { PayloadAction } from "@reduxjs/toolkit";
import { CartNotification } from "../../types";
import { NotificationActionTypes } from "../actions/notificationActions";
import store from "../store";

const initialState: CartNotification[] = [];

function notificationReducer(
  state: CartNotification[] = initialState,
  action: PayloadAction<string>
): CartNotification[] {
  switch (action.type) {
    case NotificationActionTypes.ADD:
      setTimeout(() => {
        store.dispatch({
          type: NotificationActionTypes.DEL,
          payload: state.length,
        });
      }, 10001);
      return [
        ...state,
        { message: action.payload as string, expiration: Date.now() + 10000 },
      ];
    case NotificationActionTypes.DEL:
      return state.filter((item) => item.expiration > Date.now());
    case NotificationActionTypes.CLEAR:
      return [];
    default:
      return state;
  }
}

export default notificationReducer;
