import { NavLink } from "react-router-dom";
import { Nav, Jumbotron, NavButton } from "../assets/style/header";
import logo from "../assets/img/logo.png";
import { useState } from "react";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header>
      <NavButton open={!navOpen}>
        <button onClick={() => setNavOpen(!navOpen)} />
        <div></div>
        <div></div>
      </NavButton>
      <Nav open={navOpen} collapse={true}>
        <li>
          <NavLink
            to="/"
            exact
            onClick={() => setNavOpen(false)}
            activeStyle={{
              textDecoration: "underline",
            }}
          >
            {" "}
            Home{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            onClick={() => setNavOpen(false)}
            activeStyle={{
              textDecoration: "underline",
            }}
          >
            {" "}
            About{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/products"
            onClick={() => setNavOpen(false)}
            activeStyle={{
              textDecoration: "underline",
            }}
          >
            {" "}
            Products{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/events"
            onClick={() => setNavOpen(false)}
            activeStyle={{
              textDecoration: "underline",
            }}
          >
            {" "}
            Events{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            onClick={() => setNavOpen(false)}
            activeStyle={{
              textDecoration: "underline",
            }}
          >
            {" "}
            Contact{" "}
          </NavLink>
        </li>
      </Nav>
      <Jumbotron>
        <img src={logo} alt="Cottage Accents Logo" />
        <hr />
      </Jumbotron>
    </header>
  );
};

export default Header;
