import styled from "styled-components";
import { ThemeUnselectedGray } from "../basic";

export const TextboxStyle = styled.div `
textarea {
    border-radius: 5px;
    border: 2px solid ${ThemeUnselectedGray};
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 75px;
    padding: 15px;
}`;