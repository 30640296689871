import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ProductSummeryStyle } from "../../assets/style/ProductSummeryStyle";
import { setProductPrice } from "../../redux/actions/currentActions";
import { StoreState } from "../../redux/store";
import { parseValue, Product } from "../../types";
import Slideshow from "../Slideshow";

let connection = connect(
  (state: StoreState) => ({
    current: state.current,
  }),
  { setProductPrice }
);

type ReduxProps = ConnectedProps<typeof connection>;

type Props = {
  product: Product;
};

const ProductSummery: React.FC<ReduxProps & Props> = ({
  current,
  product,
  setProductPrice,
}) => {

  let valueTableEntries: JSX.Element[] = [];
  let totalCost: number = 0;

  if (product.details.price.isBase) {
    totalCost += product.details.price.starting;
    valueTableEntries.push(
      <tr key="base">
        <td>Base Price</td>
        <td></td>
        <td>{product.details.price.starting}</td>
      </tr>
    );
  }

  let details = parseValue(current, product);
  for (const key in details) {
    if (Object.prototype.hasOwnProperty.call(details, key)) {
      const option = details[key];
      valueTableEntries.push(
          <tr key={key}>
            <td>{key}</td>
            <td>{option.data}</td>
            <td>{option.price || ""}</td>
          </tr>
        );
        totalCost += option.price || 0;
    }
  }
  /*
  for (const value in current.values) {
    if (Object.prototype.hasOwnProperty.call(current.values, value)) {
      const element = current.values[value];
      const productDetails = product.fields?.find((item) => item.id === value);
      if (!productDetails) break;

      if (productDetails.type === FieldTypes.RADIO) {
        let radioField = productDetails.field as RadioField;
        let radioData = radioField.options.find(
          (item) => item.id === element
        );
        if (radioData?.price) totalCost += radioData?.price;
        valueTableEntries.push(
          <tr key={productDetails.id + value}>
            <td>{productDetails.name}</td>
            <td>{radioData?.name}</td>
            <td>{radioData?.price || ""}</td>
          </tr>
        );
      } else if (productDetails.type === FieldTypes.COLOR) {
        let colorValue = element as ColorValue;
        valueTableEntries.push(
          <tr key={productDetails.id + value}>
            <td>Paint Pattern</td>
            <td>
              {
                (productDetails.field as ColorField).patterns.find(
                  (item) => item.id === colorValue.pattern
                )?.name
              }
            </td>
            <td></td>
          </tr>
        );
        for (const side in colorValue.color) {
          if (Object.prototype.hasOwnProperty.call(colorValue.color, side)) {
            const element = colorValue.color[side];
            valueTableEntries.push(
              <tr key={element + side}>
                <td>
                  {"Paint Color - " +
                    (productDetails.field as ColorField).patterns
                      .find((item) => item.id === colorValue.pattern)
                      ?.layouts.find((item) => item.id === side)?.name}
                </td>
                <td>
                  {
                    (productDetails.field as ColorField).colors.find(
                      (item) => item.id === element
                    )?.name
                  }
                </td>
                <td></td>
              </tr>
            );
          }
        }
      } else if (productDetails.type === FieldTypes.LETTERING) {
        let letteringValues = element as LetteringValues;
        if (!letteringValues.hasLettering) {
          valueTableEntries.push(
            <tr key={productDetails.id + value}>
              <td>{productDetails.name}</td>
              <td>No</td>
              <td></td>
            </tr>
          );
          continue;
        }
        for (const side in letteringValues.data) {
          if (
            Object.prototype.hasOwnProperty.call(letteringValues.data, side)
          ) {
            const element = letteringValues.data[side];
            if (!element.selected) continue;
            let layout = (productDetails.field as LetteringField).options
              .find((item) => item.side === side)
              ?.layouts.find((item) => item.id === element.layout);
            let color = (productDetails.field as LetteringField).colors.find(
              (item) => item.id === element.color
            );
            let font = (productDetails.field as LetteringField).fonts.find(
              (item) => item.id === element.font
            );
            if (!layout) throw new Error("Layout not found!");
            if (!color) throw new Error("Color not found!");
            if (!font) throw new Error("Font not found!");
            totalCost += layout.price;
            valueTableEntries.push(
              <tr key={productDetails.id + side}>
                <td>{productDetails.name + " - " + side}</td>
                <td>
                  <span>Layout - {layout.name}</span>
                  <br />
                  <span>Color - {color.name}</span>
                  <br />
                  <span>Font - {font.name}</span>
                </td>
                <td>{layout.price}</td>
              </tr>
            );
          }
        }
      } else if (productDetails.type === FieldTypes.SELECT) {
        let selectValues = element as SelectValue;
        let selectField = productDetails.field as SelectField;
        let selectedDetails = selectField.details.data.find(
          (item) => item.id === selectValues.selected
        );
        totalCost += selectedDetails?.price ? selectedDetails?.price : 0;
        valueTableEntries.push(
          <tr key={productDetails.id + value}>
            <td>{productDetails.name}</td>
            <td>{selectedDetails?.name}</td>
            <td>{selectedDetails?.price || ""}</td>
          </tr>
        );
      }
    }
  }*/

  useEffect(() => {
    setProductPrice(totalCost);
  }, [setProductPrice, totalCost]);

  return (
    <ProductSummeryStyle>
      {product.showImagePreview && (
        <Slideshow delay={5}>
          {product.imgs.map((url, index) => (
            <img src={url} alt="" key={index} />
          ))}
        </Slideshow>
      )}
      <table>
        <thead>
          <tr>
            <td>Item</td>
            <td>Selection</td>
            <td>Cost</td>
          </tr>
        </thead>
        <tbody>{valueTableEntries}</tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>Total</td>
            <td>${current.price}</td>
          </tr>
        </tfoot>
      </table>
    </ProductSummeryStyle>
  );
};

export default connection(ProductSummery);
