import styled from "styled-components";
import { ThemeAccentColor, ThemeLightBlue, ThemeUnselectedGray } from "../basic";

export const CustomLetteringStyle = styled.div``;

export const LetteringPreview = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

export const PreviewWrapper = styled.div`
  height: 500px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  @media only screen and (max-width: 600px) {
    height: unset;
    margin-bottom: -5px;
  }
  img {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 600px) {
      border-radius: 5px;
      height: unset;
      left: unset;
      position: relative;
      transform: unset;
      width: 100%;
    }
  }
`;

export const TopTabs = styled.div`
  input {
    display: none;
  }
  h3 {
    margin: 0;
  }

  > div {
    display: inline-block;
  }
  > div:first-child {
    margin: 0 40px;
  }

  label {
    cursor: pointer;
    text-align: center;
    div {
      text-align: center;
      border: 1px solid ${ThemeUnselectedGray};
      padding: 10px 30px 5px 30px;
      border-radius: 5px 5px 0 0;
      border-bottom: none;
      background-color:whitesmoke;
    }
  }
  label div:hover {
    background-color: ${ThemeUnselectedGray};
  }
  input:checked + label div {
    background-color: ${ThemeLightBlue};
    border: 3px solid ${ThemeLightBlue};
    border-bottom: none;
  }
`;

export const BottomTabs = styled(TopTabs)`
  label div {
    border-radius: 0 0 5px 5px;
    border: 1px solid ${ThemeUnselectedGray};
    border-top: none;
  }
  input:disabled + label div {
    background-color:${ThemeUnselectedGray};
    cursor:default;
  }
  input:checked + label div {
    border: 3px solid ${ThemeLightBlue};
    border-top: none;
  }
`;

export const Sidebar = styled.div`
  margin-top: 20px;
  label {
    font-size: 18px;
  }
  & > button {
    margin: 20px auto;
    display: block;
  }
`;

export const OptionsInputField = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-row-gap: 30px;
  align-items: center;
  @media only screen and (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  input[type="text"] {
    border: none;
    padding: 10px;
    border-bottom: 1px solid ${ThemeUnselectedGray};
  }
`;

export const CustomSelectContainer = styled.div`
  position: relative;
  input {
    position: absolute;
    opacity: 0;
  }
`;

export const SelectHeader = styled.div`
  position: relative;
  border-radius: 5px;
  button {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid ${ThemeUnselectedGray};
    cursor: ${(p: { disabled: boolean }) => p.disabled ? 'default' : 'pointer'};
  }
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 10px;
  background-color: ${(p: { disabled: boolean }) => p.disabled ? 'whitesmoke' : ''};
  &:hover {
    background-color: whitesmoke;
  }
  button:focus-visible {
    outline: 1px dashed black;
  }
  img.selectArrow {
    visibility: ${(p: { disabled: boolean }) => p.disabled ? 'hidden' : ''}
  }
`;

export const SelectOptions = styled.div`
  position: absolute;
  width: 90%;
  margin: 0 5%;
  border: 1px solid black;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  background-color: white;
  z-index: 1000;
  text-align: center;
`;

const SelectFull = styled.button`
  border-bottom: 1px solid black;
  &:last-child {
    border-bottom: none;
  }
`;

export const ImgOption = styled.button`
  grid-template-columns: auto 1fr;
  justify-items: center;
  align-items: center;
  img {
    margin: 10px;
    height: ${(p: { size: number }) => p.size + "px"};
    width: ${(p: { size: number }) => p.size + "px"};
  }
  :hover {
    background-color: whitesmoke;
  }
`;

export const ImgOptionSelected = styled.div`
  text-align: center;
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  justify-items: center;
  align-items: center;
  img {
    margin: 10px;
    height: 60px;
    width: 60px;
  }
`;

export const ColorOption = styled.button`
  margin: 10px 5px;
  width: 110px;
  div {
    width: 100px;
    height: 100px;
    background-color: ${(p: { color: string; preview?: boolean }) => p.color};
    margin: 0 auto;
  }
  :hover {
    background-color: whitesmoke;
  }
`;

export const SimpleOption = styled.button`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid black;
  :hover {
    background-color: whitesmoke;
  }
`;

export const ColorOptionSelected = styled.div`
  text-align: center;
  height: 60px;
  div {
    width: 60px;
    height: 60px;
    background-color: ${(p: { color: string }) => p.color};
  }
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const TextOption = styled(SelectFull)`
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  font-family: ${(p: { font?: string }) => p.font};
  font-size: 30px;
  padding: 10px;
  :hover {
    background-color: whitesmoke;
  }
`;

export const TextOptionSelected = styled.div`
  text-align: center;
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  font-family: ${(p: { font?: string }) => p.font};
  font-size: 30px;
  padding: 10px;
`;
