import React, { useState } from "react";
import { H1, Button } from "../assets/style/basic";
import { CartCheckoutPage } from "../assets/style/fields/cart";
import cancel from "../assets/img/cancel.svg";
import { hash, ProductValue } from "../types";
import { animated, SpringValue } from "react-spring";
import Loading from "./loading";

type props = {
  handleClose: () => void;
  style: hash<SpringValue<string>>;
  products: ProductValue[];
};

const Checkout: React.FC<props> = ({ handleClose, style, products }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("AL");
  const [zip, setZip] = useState<string>("");

  const [errors, setErrors] = useState<{
    count: number;
    errors: hash<boolean>;
  }>({ count: 0, errors: {} });
  const [backendError, setBackendError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleOrderSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    // Check for errors
    if (errors.count !== 0) {
      return;
    }
    setIsLoading(true);
    fetch(`/.netlify/functions/createOrder`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        customer: {
          fName: firstName,
          lName: lastName,
          email,
          phone,
        },
        shipping: {
          street,
          city,
          state,
          zip,
        },
        details: products,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          return {
            body: data,
            status: res.status,
          };
        });
      })
      .then((data) => {
        if (data.status !== 200) {
          if (data.body.type === 3 || data.body.type === 2) {
            if (!errors.errors[data.body.action.id]) {
              setErrors({
                count: errors.count + 1,
                errors: { ...errors.errors, [data.body.action.id]: true },
              });
            }
          } else {
            if (data.body.message) setBackendError(data.body.message);
            else
              setBackendError(
                "An unknown error ocurred. Try again later or contact us."
              );
          }
          setIsLoading(false);
        } else {
          window.location.href = data.body.url;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <animated.div style={style}>
      <CartCheckoutPage>
        <H1>Checkout</H1>
        {!isLoading && (
          <button className="cart-close" onClick={() => handleClose()}>
            <img src={cancel} alt="close cart" />
          </button>
        )}
        <form onSubmit={handleOrderSubmit} id="checkout-form">
          <h2>Personal info </h2>
          <div id="personal">
            <label id="fName" className={errors.errors["fName"] ? "error" : ""}>
              First Name:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="given-name"
                value={firstName}
                onChange={(e) => {
                  if (
                    !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.fName)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, fName: true },
                      });
                  } else {
                    if (errors.errors.fName)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, fName: false },
                      });
                  }
                  setFirstName(e.target.value);
                }}
              />
            </label>
            <label id="lName" className={errors.errors["lName"] ? "error" : ""}>
              Last Name:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => {
                  if (
                    !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.lName)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, lName: true },
                      });
                  } else {
                    if (errors.errors.lName)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, lName: false },
                      });
                  }
                  setLastName(e.target.value);
                }}
              />
            </label>
            <label id="email" className={errors.errors["email"] ? "error" : ""}>
              Email:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  if (
                    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.email)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, email: true },
                      });
                  } else {
                    if (errors.errors.email)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, email: false },
                      });
                  }
                  setEmail(e.target.value);
                }}
              />
            </label>
            <label id="tel" className={errors.errors["tel"] ? "error" : ""}>
              Phone Number:
              <input
                disabled={isLoading}
                required={true}
                type="tel"
                autoComplete="mobile tel"
                value={phone}
                onChange={(e) => {
                  if (
                    !/^[0-9]{10}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.tel)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, tel: true },
                      });
                  } else {
                    if (errors.errors.tel)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, tel: false },
                      });
                  }
                  setPhone(e.target.value);
                }}
              />
            </label>
          </div>
          <h2>Shipping info</h2>
          <div id="shipping">
            <label
              id="address"
              className={errors.errors["address"] ? "error" : ""}
            >
              Street:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="street-address"
                value={street}
                onChange={(e) => {
                  if (!/^\w+(\s\w+){2,}$/i.test(e.target.value)) {
                    if (!errors.errors.address)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, address: true },
                      });
                  } else {
                    if (errors.errors.address)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, address: false },
                      });
                  }
                  setStreet(e.target.value);
                }}
              />
            </label>
            <label id="city" className={errors.errors["city"] ? "error" : ""}>
              City:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="address-level2"
                value={city}
                onChange={(e) => {
                  if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(e.target.value)) {
                    if (!errors.errors.city)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, city: true },
                      });
                  } else {
                    if (errors.errors.city)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, city: false },
                      });
                  }
                  setCity(e.target.value);
                }}
              />
            </label>
            <label id="state">
              State:
              <select
                disabled={isLoading}
                required={true}
                autoComplete="address-level1"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </label>
            <label
              id="postal"
              className={errors.errors["postal"] ? "error" : ""}
            >
              Zip Code:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="postal-code"
                value={zip}
                onChange={(e) => {
                  if (!/^\d{5}(?:[-\s]\d{4})?$/i.test(e.target.value)) {
                    if (!errors.errors.postal)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, postal: true },
                      });
                  } else {
                    if (errors.errors.postal)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, postal: false },
                      });
                  }
                  setZip(e.target.value);
                }}
              />
            </label>
          </div>
        </form>
        {errors.count !== 0 && (
          <p className="ErrorText">
            Please fix errors highlighted in red before continuing.
          </p>
        )}
        {backendError && <p className="ErrorText">{backendError}</p>}
        {isLoading ? (
          <Loading />
        ) : (
          <Button type="submit" id="submit" form="checkout-form">
            Proceed
          </Button>
        )}
      </CartCheckoutPage>
    </animated.div>
  );
};

export default Checkout;
