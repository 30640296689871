import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "../../assets/style/basic";
import { StoreState } from "../../redux/store";
import { createProduct } from "../../redux/actions/currentActions";
import { Product, ProductValue } from "../../types";
import { ProductPageStyle } from "../../assets/style/ProductPage";
import { addProductToCart } from "../../redux/actions/cartActions";
import Slideshow from "../../components/Slideshow";

let connector = connect(
  (state: StoreState) => ({
    products: state.products,
    current: state.current,
  }),
  { createProduct, addProductToCart }
);

type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
  product: Product;
  addToCartHandler: (a: ProductValue) => void;
}

const SimpleProduct: React.FC<ReduxProps & Props> = ({
  createProduct,
  product,
  addToCartHandler,
  current,
}) => {
  useEffect(() => {
    if (product.canStack) {
      createProduct({
        price: product.details.price.starting,
        id: product.details.id,
        values: {},
        quantity: 1,
      });
    } else {
      createProduct({
        price: product.details.price.starting,
        id: product.details.id,
        values: {},
        quantity: 1,
      });
    }
  }, [createProduct, product.canStack, product.details.id, product.details.price.starting]);

  // Once a user submits, add order to the cart
  function handleSubmit() {
    addToCartHandler(current);
  }

  return (
    <ProductPageStyle>
      <div>
        <Slideshow delay={5}>
          {product.imgs.map((url, index) => (
            <div key={index}>
              <img src={url} alt=""/>
            </div>
          ))}
        </Slideshow>
        <Button onClick={handleSubmit}> Submit Order </Button>
      </div>
      <div>
        <h2>{product.details.name}</h2>
        <p>${product.details.price.starting} + tax</p>
        <p>{product.details.description.short}</p>
        <p>{product.details.description.full}</p>
      </div>
    </ProductPageStyle>
  );
};

export default connector(SimpleProduct);
