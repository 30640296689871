import styled from "styled-components";
import { ThemeLightBlue } from "./basic";

import JumbotronImg from "../img/header-bg.jpg";
import Rope from "../img/rope.svg";

/**
 * Style for the navbar.
 */

export const NavButton = styled.div`
  @media only screen and (min-width: 701px) {
    display: none;
  }
  width: 100%;
  height: 60px;
  background-color: #539ebb;
  position: relative;
  z-index: 55;
  button {
    z-index: 100;
    position: absolute;
    width: 70%;
    height: 100%;
  }
  div {
    position: absolute;
    width: 40px;
    height: 3px;
    border-radius: 5px;
    left: 30px;
    &:nth-child(2) {
      top: calc(50% - 7px);
    }
    &:last-child {
      top: calc(50% + 7px);
    }
    background-color: white;
    transition: all 0.3s ease-in-out;
    ${(p: { open: boolean }) => {
      if (!p.open) {
        return `
          &:nth-child(2) {
            top:23px;
            transform-origin: center;
            transform: rotate(45deg);
          }
          &:last-child {
            top:23px;
            transform-origin: center;
            transform: rotate(-45deg);
          }
        `;
      } else {
        return `
          &:first-child {
            transform-origin: center;
            transform: rotate(0deg);
          }
          &:last-child {
            transform-origin: center;
            transform: rotate(0deg);
          }
        `;
      }
    }}
  }
`;

export const Nav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${ThemeLightBlue};
  text-align: center;
  position: relative;
  @media only screen and (max-width: 700px) {
    ${(p: { open: boolean; collapse: boolean }) => {
      if (p.collapse && p.open) {
        return "max-height: 400px;"
      }
      else if (p.collapse && !p.open) {
        return "max-height: 0px;"
      }
    }}
    transition: max-height 0.5s ease-in;
  }
  li {
    @media only screen and (max-width: 700px) {
      width: 100%;
      display: block;
    }
    display: inline-block;
    padding: 22px 30px;
    a {
      color: white;
      font-size: 20px;
      line-height: 20px;
    }
  }
`;

/**
 * Style for the main Jumbotron.
 * It has the logo and rope border.
 */
export const Jumbotron = styled.div`
  background-image: url(${JumbotronImg});
  background-position: 0% 60%;
  background-size: cover;
  position: relative;
  height: 350px;
  @media only screen and (max-width: 650px) {
    height: auto;
    margin-bottom: 30px;
  }
  width: 100%;
  margin-bottom: 110px;
  img {
    height: 500px;
    width: auto;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    @media only screen and (max-width: 650px) {
      position: relative;
      height: auto;
      width: 80%;
      margin: 0 10%;
    }
  }
  hr {
    position: absolute;
    bottom: 0;
    margin: 0;
    display: block;
    border: 0;
    height: 27px;
    width: 100%;
    background-image: url(${Rope});
  }
`;
