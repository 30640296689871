import styled from "styled-components";
import { ThemeSelectGray, ThemeUnselectedGray } from "../basic";

const BasicRadio = styled.div`
  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    margin: 0 auto;
  }
`;

export const RadioBarStyle = styled(BasicRadio)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    margin: 0 40px;
  }
  label {
    &:nth-child(2) {
      border-radius: 5px 0px 0px 5px;
      @media only screen and (max-width: 550px) {
        border-radius: 5px 5px 0px 0px;
        border-left-width: 2px;
      }
    }
    &:last-child {
      border-radius: 0px 5px 5px 0px;
      @media only screen and (max-width: 550px) {
        border-radius: 0px 0px 5px 5px;
      }
    }
    border: 2px solid ${ThemeUnselectedGray};
    border-left-width: 1px;
    border-right-width: 1px;
    padding: 30px 25px 5px 25px;
    cursor: pointer;
    &:hover {
      border-color: ${ThemeSelectGray};
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  input:checked + label {
    background-color: ${ThemeUnselectedGray};
  }
`;

export const RadioImgStyle = styled(BasicRadio)`
  input:checked + label > div {
    border: 4px solid ${ThemeSelectGray};
  }
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 1350px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 950px) {
    grid-template-columns: 1fr;
  }
  justify-items: center;
  label {
    cursor: pointer;
    position:relative;
    & > div {
      box-sizing: border-box;
      &:hover {
        border-color: ${ThemeSelectGray};
      }
      border: 2px solid ${ThemeUnselectedGray};
      border-radius: 5px;
      height: 250px;
      width: 400px;
      @media only screen and (max-width: 500px) {
        width: 90vw;
      }
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      div {
        z-index: 10;
        text-align: center;
        width: 100%;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export const RadioCardStyle = styled(BasicRadio)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 950px) {
    grid-template-columns: 1fr;
  }
  justify-items: center;
  input:checked + label > div {
    box-sizing: border-box;
    border: 4px solid ${ThemeSelectGray};
  }
  label > div:hover {
    border: 2px solid ${ThemeSelectGray};
  }
`;
