import { H2, P, H3 } from "../../assets/style/basic";
import {
  EventCalCard,
} from "../../assets/style/cards";
import Info from "../../assets/img/info.svg"

interface Props {
  item: string;
}

export const EventCard:React.FC<Props> = ({ item }) => {
  return (
    <EventCalCard>
      <H3>Jan</H3>
      <H2>{item}</H2>
      <div>
        <P>9:30 - 4:00</P>
        <P>Falmouth Rode Show</P>
      </div>
      <img src={Info} alt="Info button"/>
    </EventCalCard>
  );
};
