import styled from "styled-components";

export const ThemeLightBlue = "#539EBB";
export const ThemeAccentColor = "#66BAA8";
export const ThemeSelectGray = "#757575";
export const ThemeUnselectedGray = "#BBB";

export const H1 = styled.h1`
  margin: 0;
  font-family: "Lobster", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
`;

export const H2 = styled.h2`
  margin: 0 0 15px 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
`;

export const H3 = styled.h3`
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
`;

export const P = styled.p`
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
`;

export const PageWrapper = styled.div`
  max-width: 1600px;
  width: 90%;
  margin: auto;
   @media only screen and (max-width: 850px) {
    width: 90%;
    margin: 0 5%;
   }
`;

export const Button = styled.button`
  background-color: ${ThemeAccentColor};
  color: white;
  font-family: "Roboto";
  border-radius: 5px;
  padding: 15px 60px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1, 1.1);
  }
`;

export const CarouselStyle = styled.div`
  max-width: 100%;
  > img {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    max-width: 100%;
    border-radius: 5px;
  }
`;

export const Controlls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  > * {
    transition: transform 0.2s ease-in-out;
  }
  > :hover {
    transform: scale(1.3, 1.3);
  }
  img:first-child {
    margin-right: 10px;
  }
  img:last-child {
    margin-left: 10px;
  }
  div:nth-child(${(p: { active: number }) => p.active + 1}) {
    background-color: ${ThemeSelectGray};
  }
  div {
    margin: 0 15px;
    height: 10px;
    width: 10px;
    background-color: ${ThemeUnselectedGray};
    border-radius: 50%;
    display: inline-block;
  }
`;

export const InputWithLabel = styled.div`
  width: 100%;
  > * {
    display: block;
  }
  label {
    text-align: left;
    margin-bottom: 5px;
  }
  input {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: 0;
    padding: 10px 7px;
    border: none;
    border-radius: 5px;
  }
`;

export const FooterStyle = styled.div`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  margin-top: 90px;
  padding-bottom: 60px;
  width: 100%;
  background-color: rgb(43, 43, 43);
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "a b" "c c" "d e";
  @media only screen and (max-width: 950px) {
    grid-template-areas: "a a" "b b" "c c" "d e";
  }
  @media only screen and (max-width: 400px) {
    grid-template-areas: "a a" "b b" "c c" "e e" "d d";
  }
  > div {
    @media only screen and (max-width: 950px) {
      padding: 25px 0 25px;
      text-align:center;
    }
    padding: 50px 100px 0 100px;
  }
  .footer-moto {
    grid-area: a;
    @media only screen and (max-width: 950px) {
      margin: 0 50px;
    }
  }
  .footer-nav {
    grid-area: b;
  }
  .footer-icons {
    grid-area: c;
    display: flex;
    justify-content: center;
    > img {
      margin: 0 20px;
    }
  }
  .footer-cr {
    grid-area: d;
  }
  .footer-credit {
    grid-area: e;
    text-align: right;
    @media only screen and (max-width: 950px) {
      text-align:center;
    }
  }
`;

export const Price = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
`;
