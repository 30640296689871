import styled from "styled-components";

export const ProductSummeryStyle = styled.div`
  table {
    border-radius: 5px;
    margin: 20px 0;
    background: #69bac2;
    background: linear-gradient(123deg, #69bac2, #5382ac);
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    thead,
    tfoot {
      text-align: left;
      font-weight: bold;
    }
    tbody {
      tr {
        background-color: hsla(0, 0%, 100%, 0.3);
      }
      > tr:nth-child(2n-1) {
        background-color: white;
      }
    }
    td {
      padding: 15px;
      vertical-align: top;
    }
  }
`;

export const ProductSummeryFloatContainer = styled.div`
  position: sticky;
  width: 100%;
  top: 30px;
  transition: top 0.05s linear;
  @media only screen and (max-width: 1100px) {
    width: 80%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;
