import { Product } from "../../types";

export enum ProductActionTypes {
  ADD = "addproduct",
  FETCH = "fetchproducts",
}

export const addProduct =
  (productId: string) =>
  async (
    dispatch: (a: { type: ProductActionTypes; payload: Product }) => void
  ) => {
    dispatch({
      type: ProductActionTypes.ADD,
      payload: await fetchProductHelper(productId),
    });
  };

export const fetchProducts =
  () =>
  async (
    dispatch: (a: { type: ProductActionTypes; payload: Product[] }) => void
  ) => {
    dispatch({
      type: ProductActionTypes.FETCH,
      payload: await fetchProductsDetailsHelper(),
    });
  };

async function fetchProductsDetailsHelper() {
  let res = await fetch(`/.netlify/functions/getProducts`, {
    method: "GET",
    mode: "cors",
  });
  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }
  let res_parsed = await res.json();

  return res_parsed as Product[];
}

async function fetchProductHelper(id: string) {
  let res = await fetch(`/.netlify/functions/getFullProduct`, {
    method: "POST",
    mode: "cors",
    body: id,
  });
  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }
  let res_parsed = await res.json();

  return res_parsed as Product;
}
