import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addProductToCart } from "../../redux/actions/cartActions";
import { addProduct } from "../../redux/actions/productActions";
import { StoreState } from "../../redux/store";
import { Product, ProductValue } from "../../types";
import CustomProduct from "./CustomProduct";
import SimpleProduct from "./SimpleProduct";

let connector = connect(
  (state: StoreState) => ({
    products: state.products,
  }),
  { addProductToCart, addProduct }
);

type ReduxProps = ConnectedProps<typeof connector>;

const ProductPage: React.FC<ReduxProps> = ({ products, addProductToCart, addProduct }) => {
  let { id } = useParams<{ id: string }>();
  let product = products.find((item) => item.details.id === id) as Product | undefined;

  const history = useHistory();

  const [loading, setLoading] = useState(false)

  function handleSubmit(current: ProductValue) {
    if (!current)
      throw new Error("Trying to add product to cart that doesn't exist.");
    history.push("/products");
    window.scrollTo(0, 0);
    addProductToCart(current);
  }

  // Error page if no product was found
  if (!product) {
    return (
      <div>
        <p>Product not found!</p>
      </div>
    );
  }

  // If we haven't fetched the product details, do it
  if (!product.type && !loading) {

    addProduct(product.details.id);
    setLoading(true);
  }

  if (product.type && loading) {
    setLoading(false);
  }

  if(loading) {
    return (
      <div>
        <p>Loading product details...</p>
      </div>
    );
  }

  // If there aren't any fields, it is a simple product
  if (!product.fields) {
    return <SimpleProduct addToCartHandler={handleSubmit} product={product} />;
  }
  // Fields means it's a custom product
  return <CustomProduct addToCartHandler={handleSubmit} product={product} />;
};

export default connector(ProductPage);
