import React from "react";
import Slider, { Settings } from "react-slick";

interface Props {
  children: JSX.Element[];
  delay:number;
}

const Slideshow:React.FC<Props> = ({children, delay}) => {
  var slideShowSettings: Settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
    autoplay: delay !== 0,
    autoplaySpeed: delay * 1000
  };

  return <Slider {...slideShowSettings}>{children}</Slider>;
};

export default Slideshow;
