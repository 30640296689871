import styled from "styled-components";

export const ShopFeaturedWrapper = styled.div `
width: 80%;
margin: 0 auto;
> div {
    margin: 30px 0;
}
`
export const CategoryGrid = styled.div `
display:grid;
grid-template-columns: 1fr 1fr;
@media only screen and (max-width: 900px) {
    grid-template-columns: 100%;
}
grid-row-gap: 50px;
justify-items: center;
margin: 30px;
`