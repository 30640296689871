import { PayloadAction } from "@reduxjs/toolkit";
import { ProductValue } from "../../types";
import { CartActionTypes } from "../actions/cartActions";

const initialState: ProductValue[] = [];

function cartReducer(
  state: ProductValue[] = initialState,
  action: PayloadAction<ProductValue | number>
): ProductValue[] {
  switch (action.type) {
    case CartActionTypes.ADD:
      let product = action.payload as ProductValue;
      // If an item can stack, check if the item is the same and has the same values
      if (product.quantity !== null) {
        let safeState = [...state];
        for (let index = 0; index < safeState.length; index++) {
          let item = safeState[index];

          if (
            item.id === product.id &&
            isObjectsEqual(item.values, product.values)
          ) {
            if (!item.quantity) item.quantity = 2;
            else item.quantity++;
            safeState[index] = item;
            return safeState;
          }
        }
      }
      return [...state, product];
    case CartActionTypes.DEL:
      return state.filter((_, index) => index !== (action.payload as number));
    default:
      return state;
  }
}

export default cartReducer;

// Helper function
function isObjectsEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1: any = object1[key];
    const val2: any = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isObjectsEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}
