import React from 'react'
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { Button, H1, H2 } from '../assets/style/basic';

const Confirm = () => {
    let query = useQuery();
    let history = useHistory();
    let email = query.get('email');
    let id = query.get('id');
    if (!email || !id) {
        return <div></div>
    }
    return (
        <ConfirmStyle>
            <H1>Order Confirmed!</H1>
            <p>Thank you for your order, we will start working on it right away.</p>
            <p>We will email you at <span>{decodeURI(email)}</span> with your receipt and reach out when your order is shipped.</p>
            <p>The order ID is <span>{decodeURI(id)}</span>, please keep this in your records as reference to this order.</p>
            <p>If you have any issues, comments, or concerns feel free to reach out to us at <span>cottageaccents11@gmail.com</span>.</p>
            <Button onClick={()=> {
                history.push('./products')
            }}>Back to products</Button>
        </ConfirmStyle>
    )
}

let ConfirmStyle = styled.div`
    text-align:center;
    padding-top: 30px;
    span {
        font-weight: bolder;
    }
    button {
        margin-top: 30px;
    }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



export default Confirm
