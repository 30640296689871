import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import currentReducer from "./currentReducer";
import notificationReducer from "./notificationReducer";
import productsReducer from "./productsReducer";

let rootReducer = combineReducers({
  products: productsReducer,
  current: currentReducer,
  cart: cartReducer,
  notifications: notificationReducer,
});

export default rootReducer;
