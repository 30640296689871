import { PayloadAction } from "@reduxjs/toolkit";
import { ProductValue, Value } from "../../types";
import { CurrentActionTypes } from "../actions/currentActions";

const initialState: ProductValue = {
  price: 0,
  id: "",
  values: {},
  quantity: 0,
};

function currentReducer(
  state: ProductValue = initialState,
  action: PayloadAction<Value | ProductValue | number, string, { id: string }>
): ProductValue {
  switch (action.type) {
    case CurrentActionTypes.CREATE:
      let values = action.payload as ProductValue;
      return values;
    case CurrentActionTypes.PRICE:
      return { ...state, price: action.payload as number };
    case CurrentActionTypes.UPDATE:
      let value = action.payload as Value;
      if (!state) throw new Error("Updating a current not yet created.");
      return { ...state, values: { ...state.values, [action.meta.id]: value } };
    case CurrentActionTypes.CLEAR:
      return { price: 0, id: "", values: {}, quantity: 0 };
    default:
      return state;
  }
}

export default currentReducer;
