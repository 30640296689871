import React from "react";
import styled from "styled-components";
import { ThemeAccentColor } from "../assets/style/basic";

const Loading = () => {
  return (
    <LoadingStyle>
      <div></div>
    </LoadingStyle>
  );
};

let LoadingStyle = styled.div`
  height: 120px;
  width: 120px;
  margin: 15px auto 0;
  div {
    border: 5px solid #f3f3f3;
    border-top: 5px solid ${ThemeAccentColor};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
