import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { StoreState } from '../../redux/store'
import {updateProduct} from '../../redux/actions/currentActions'
import { H2 } from '../../assets/style/basic';
import CustomSelectInternal from './CustomSelectInternal';
import { SelectDetails } from '../../types';

let connector = connect(
    (state:StoreState) => ({
        current:state.current
    }),
    {updateProduct}
);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
    id:string;
    isRequired?:boolean;
    options: SelectDetails;
    name:string;
}

const CustomSelect: React.FC<Props & ReduxProps> = ({
    current,
    updateProduct,
    id,
    options,
    name,
    isRequired,
}) => {

    let currentFieldData = current.values[id] as string;

    function handleSelectChange(this:string, value:string) {
        updateProduct(value, id)
    }

    return (
        <div>
            <H2>{name}</H2>
            <CustomSelectInternal
                isRequired={isRequired}
                isCompleted={currentFieldData !== ""}
                onChange={handleSelectChange}
                value={currentFieldData}
                options={options}
            />
        </div>
    )
}

export default connector(CustomSelect)
