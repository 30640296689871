import styled from "styled-components";
import { ThemeUnselectedGray } from "./basic";

export const SimpleCard = styled.div`
  width: 350px;
  padding-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid ${ThemeUnselectedGray};
  div:first-child {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    height: 250px;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  p,
  h3 {
    text-align: center;
    padding: 0 30px;
    margin: 20px 0;
  }
  @media (max-width: 1500px) {
    width: 300px;
  }
`;

export const FeaturedSmall = styled.div`
  width: 350px;
  height: 415px;
  overflow: auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 25px auto;
  background-color: white;
  img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 250px;
  }
  p,
  h2 {
    padding: 0 30px;
    margin: 20px 0;
  }
  &:hover {
    transform: scale(1.1, 1.1);
    @media only screen and (max-width: 400px) {
      transform: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    }
  }
`;

export const FeaturedLarge = styled.div`
  display: grid;
  grid-template-columns: 55% auto;
  > div:last-child {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin: 0% 60px 15%;
    @media only screen and (max-width: 950px) {
      margin: 0;
    }
    > * {
      @media only screen and (max-width: 950px) {
      margin: 0 0 20px;
    }
    }
  }
  @media only screen and (max-width: 950px) {
    grid-template-columns: 100%;
  }
`;

export const BasicProduct = styled(FeaturedSmall)`
  transition: none;
  cursor: auto;
  height: 475px;
  button {
    margin: 0 auto;
    display: block;
  }
  &:hover {
    transform: none;
  }
`;

export const EventCalCard = styled.div`
  height: 300px;
  width: 300px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 15px auto;
  position:relative;
  h3 {
    margin: 0;
    width: 100%;
    text-align: center;
    background-color: #c93e3e;
    color: white;
    padding: 25px 0;
    border-radius: 5px 5px 0 0;
  }
  h2 {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px;
    margin: 0;
  }
  div {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
    p {
      font-size: 22px;
      margin: 10px;
    }
  }
  img {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    color: #fff;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
`;

export const CollectionControlWrapper = styled.div`
  position: relative;
  > button {
    position: absolute;
    top: 50%;
    transform: transition(-50%);
    transition: transform 0.2s ease-in-out;
    &:first-child {
      left: -40px;
    }
    &:last-child {
      right: -40px;
    }
    &:hover {
      transform: scale(1.3, 1.3);
    }
  }
`;

export const CollectionContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 30px 0;
  box-sizing: content-box;
`;

export const CollectionStyle = styled.div`
  transition: left 0.75s ease-in-out;
  top: 30px;
  left: 0;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  > div {
    margin: 0 20px;
  }
`;

export const HiddenPrevious = styled(CollectionStyle)`
  position:absolute;
  left: -100%;
`;

export const HiddenNext = styled(CollectionStyle)`
  position:absolute;
  left: 100%;
`;
