import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTransition } from "react-spring";
import { NotificationCard } from "../assets/style/fields/cart";
import { StoreState } from "../redux/store";

let connector = connect(
  (state: StoreState) => ({
    notifications: state.notifications,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Notifications: React.FC<ReduxProps> = ({ notifications }) => {
  const transition = useTransition(notifications, {
    from: {
      opacity: "0",
      right: "-100%",
      transform:"scaleY(1)"
    },
    enter: {
      right: "0%",
      opacity: "100%",
      transform:"scaleY(1)"
    },
    leave: {
      transformOrigin: "top left",
      opacity: "0",
      transform:"scaleY(0)"
    },
  });

  return transition((style, item) => {
    return item ? (
      <NotificationCard style={style}>
        <h3>{item.message}</h3>
        <div className="timer"></div>
      </NotificationCard>
    ) : (
      ""
    );
  });
};

export default connector(Notifications);
