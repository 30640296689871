import React from "react";
import { Button, H2, P } from "../../assets/style/basic";
import { FeaturedLarge, FeaturedSmall } from "../../assets/style/cards";
import { useHistory } from "react-router-dom";
import Slideshow from "../Slideshow";

interface Props {
  isLarge: boolean;
  id: string;
  description: string;
  price: number;
  name: string;
  canCustomize: boolean;
}

const FeaturedCard: React.FC<Props> = ({
  isLarge,
  id,
  description,
  price,
  name,
  canCustomize,
}) => {

  const history = useHistory()

  function handleClick () {
    history.push("/products/"+id);
  }

  if (!isLarge) {
    return (
      <FeaturedSmall>
        <button onClick={handleClick}>
          <img
            src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
            alt=""
          />
          <div>
            <H2>{name}</H2>
            <P>{description}</P>
          </div>
        </button>
      </FeaturedSmall>
    );
  }
  return (
    <FeaturedLarge>
      <Slideshow delay={0}>
        <img
          src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
          alt=""
        />
        <img
          src="https://cottageaccents.files.wordpress.com/2015/05/sam_0483.jpg"
          alt=""
        />
      </Slideshow>
      <div>
        <H2>{name}</H2>
        <P>
          {description}
        </P>
        <P>Starting at ${price} + tax</P>
        <Button onClick={handleClick}>{canCustomize ? "Customize" : "Details"}</Button>
      </div>
    </FeaturedLarge>
  );
};

export default FeaturedCard;
