import React, { useState } from "react";
import {
  CartBody,
  CartButton,
  CartCard,
  EmptyCart,
  CartClosed,
} from "../assets/style/fields/cart";
import cart_img from "../assets/img/cart_img.svg";
import { Button, H1 } from "../assets/style/basic";
import { StoreState } from "../redux/store";
import { connect, ConnectedProps } from "react-redux";
import { Product, ProductTypes, ProductValue } from "../types";
import trash from "../assets/img/trash.svg";
import { deleteProductFromCart } from "../redux/actions/cartActions";
import { createProduct } from "../redux/actions/currentActions";
import { useHistory } from "react-router-dom";
import view from "../assets/img/view.svg";
import cancel from "../assets/img/cancel.svg";
import sad from "../assets/img/sad_face.svg";
import { animated, config, useTransition } from "react-spring";
import { clearNotifications } from "../redux/actions/notificationActions";
import Checkout from "./checkout";
import Notifications from "./Notifications";

let connector = connect(
  (state: StoreState) => ({
    products: state.products,
    cart: state.cart,
  }),
  { deleteProductFromCart, createProduct, clearNotifications }
);

type ReduxProps = ConnectedProps<typeof connector>;

const Cart: React.FC<ReduxProps> = ({
  products,
  cart,
  deleteProductFromCart,
  createProduct,
  clearNotifications,
}) => {
  const [isCartOpen, setCartOpen] = useState<boolean>(false);
  const [isCheckoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const history = useHistory();

  const closedTransition = useTransition(!(isCartOpen || isCheckoutOpen), {
    from: {
      opacity: "0",
    },
    enter: {
      opacity: "100",
    },
    leave: {
      opacity: "0",
    },
    config: config.default,
  });
  const cartTransition = useTransition(isCartOpen, {
    from: {
      right: "-800px",
    },
    enter: {
      right: "0px",
    },
    leave: {
      right: "-800px",
    },
    config: config.default,
  });
  const fullTransition = useTransition(isCartOpen || isCheckoutOpen, {
    from: {
      opacity: "0",
    },
    enter: {
      opacity: "100",
    },
    leave: {
      opacity: "0",
    },
    expires: true,
    config: config.default,
  });
  const checkoutTransition = useTransition(isCheckoutOpen, {
    from: {
      opacity: '0'
    },
    enter: {
      opacity: '1'
    },
    leave: {
      opacity: '0'
    },
    config: config.default,
  });

  let totalCost: number = 0;

  // Find details about a product given its id
  // TODO: check if the product's info has actually been loaded, if not, call GetFullProduct and wait...
  // This works fine for now bc a product cant be added to a cart unless its loaded
  function getProductDetails(prodID: string) {
    let result = products.find((item) => item.details.id === prodID);
    if (!result) throw new Error("Product not found!");
    return result as Product;
  }

  // Generates a list of product cards and calculates total price
  function createCartEntry(
    product: Product,
    data: ProductValue,
    index: number
  ) {
    //TODO: make quantity buttons work.
    totalCost += data.price * (data.quantity ? data.quantity : 1);
    return (
      <CartCard>
        <img src={product.imgs[0]} alt={product.details.name} />
        <div>
          <h2>{product.details.name}</h2>
        </div>
        <div>
          <h3>${data.price * data.quantity}</h3>
          {product.canStack && <p> - {data.quantity} +</p>}
          {(product.type === ProductTypes.CUSTOM || product.type === ProductTypes.BASIC_VARIANT) && (
            <button
              onClick={() => {
                createProduct(data);
                setCartOpen(false);
                deleteProductFromCart(index);
                history.push("/products/" + data.id);
                window.scrollTo(0, 0);
              }}
            >
              <img src={view} alt="Edit item" />
            </button>
          )}
          <button
            onClick={() => {
              deleteProductFromCart(index);
            }}
          >
            <img src={trash} alt="delete item" />
          </button>
        </div>
      </CartCard>
    );
  }

  return (
    <>
      {closedTransition((style, check) => {
        return check ? (
          <CartClosed style={style}>
            <CartButton
              onClick={() => {
                setCartOpen(true);
                clearNotifications();
              }}
            >
              <img src={cart_img} alt="shopping cart" />
            </CartButton>
            <Notifications />
          </CartClosed>
        ) : (
          ""
        );
      })}
      {fullTransition((style, check) => {
        return check ? (
          <CartBody style={style}>
            <div className="hidden-background"></div>
            {cartTransition((style, check) => {
              return check ? (
                <animated.div style={style} className="cartContent">
                  <button
                    className="cart-close"
                    onClick={() => setCartOpen(false)}
                  >
                    <img src={cancel} alt="close cart" />
                  </button>
                  <H1>Cart</H1>
                  {cart.length === 0 ? (
                    <EmptyCart>
                      <h2>You don't have anything in your cart</h2>
                      <img src={sad} alt="sad face" />
                    </EmptyCart>
                  ) : (
                    <ul>
                      {cart.map((item, index) => (
                        <li>
                          {createCartEntry(
                            getProductDetails(item.id),
                            item,
                            index
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                  {cart.length === 0 ? (
                    <Button
                      onClick={() => {
                        setCartOpen(false);
                        history.push("/products");
                        window.scrollTo(0, 0);
                      }}
                    >
                      View Products
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setCartOpen(false);
                        setCheckoutOpen(true);
                      }}
                    >
                      ${totalCost} - Checkout
                    </Button>
                  )}
                </animated.div>
              ) : (
                ""
              );
            })}
            {checkoutTransition((style, check) => {
              return check ? (
                <Checkout style={style} handleClose={() => setCheckoutOpen(false)} products={cart}/>
              ) : ""
            })}
          </CartBody>
        ) : (
          ""
        );
      })}
    </>
  );
};

export default connector(Cart);
