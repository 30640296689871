import { PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../../types";
import { ProductActionTypes } from "../actions/productActions";

const initialState: Product[] = [];

function productsReducer(
  state: Product[] = initialState,
  action: PayloadAction<Product | Product[]>
): Product[] {
  switch (action.type) {
    case ProductActionTypes.ADD:
      console.log("Adding product to products...");
      let fullProduct = action.payload as Product;
      let temp = [...state];
      let added = temp.some((item, index) => {
        if (item.details.id === fullProduct.details.id) {
          temp[index] = fullProduct;
          console.log(fullProduct);

          return true;
        }
        return false;
      });
      if (!added) temp.push(fullProduct);

      return temp;
    case ProductActionTypes.FETCH:
      console.log("Fetching products...");
      return action.payload as Product[];
    default:
      return state;
  }
}

export default productsReducer;
