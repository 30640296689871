import styled from "styled-components";

export const CustomProductPageStyle = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 60px;
  .product-info button {
    margin: 0 auto;
    display: block
  }
  > div {
    position: relative;
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 100%;
  }
`;

export const ProductPageStyle = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 60px;
  & > * {
    margin: 20px 15px;
  }
  h2 {
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    margin: 75px 0 0 0;
  }
  p {
    margin-right: 30px;
  }
  img {
    max-height: 400px;
    max-width: 100%;
    margin: 0 auto;
  }
  button {
    margin: 30px auto;
    display: block;
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 100%;
    grid-gap: 0px;
    h2 {
      margin-top: 0;
    }
  }
`;

export const ProductOptions = styled.div`
  > form {
    padding: 20px;
  }
`;
