import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, H3, Price } from "../../assets/style/basic";
import {
  BottomTabs,
  CustomLetteringStyle,
  LetteringPreview,
  OptionsInputField,
  PreviewWrapper,
  Sidebar,
  TopTabs,
} from "../../assets/style/fields/CustomLetteringStyle";
import { updateProduct } from "../../redux/actions/currentActions";
import { StoreState } from "../../redux/store";
import {
  Color,
  Logo,
  Font,
  Lettering,
  LetteringLayout,
  Layout,
  SelectTypes,
  LetteringValue,
  hash,
} from "../../types";
import CustomSelectInternal from "./CustomSelectInternal";

let connector = connect(
  (state: StoreState) => ({
    current: state.current,
  }),
  { updateProduct }
);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  name: string;
  colors: Color[];
  logos: Logo[];
  fonts: Font[];
  options: Lettering;
  id: string;
};

const CustomLetteringNew: React.FC<Props & ReduxProps> = ({
  name,
  colors,
  logos,
  fonts,
  options,
  id,
  updateProduct,
  current,
}) => {
  // Holds elements for what is shown
  let renderedTops: JSX.Element[] = [];
  let renderedBottoms: JSX.Element[] = [];

  // Table of options' keys to allow options hash to be indexable
  let optionKeys = Object.keys(options);

  // The currently set values for lettering, useful for populating already submitted fields
  let currentLetteringValue = current.values[id] as hash<LetteringValue>;

  //Holds the states of the selected tabs and if lettering is open
  const [isOpen, setIsOpen] = useState<boolean>(Object.keys(currentLetteringValue).length > 0);
  const [selectedSide, setSelectedSide] = useState<number>(0);
  const [selectedLayout, setSelectedLayout] = useState<number>(0);
  const [letteringOptions, setLetteringOptions] = useState<LetteringValue>();

  // When a new side is selected handle how to react.
  // index - the index of the selected side.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSideChange(index: number) {
    // Gets data at new side if it exists
    let selectedData = currentLetteringValue[optionKeys[index]];
    setSelectedSide(index);

    // On the changed side, if a layout is already selected, set index to the selected layout
    if (selectedData) {
      let layoutIndex = options[optionKeys[index]].findIndex(
        (item) => item.id === selectedData.layout
      );
      handleLayoutChange(layoutIndex > -1 ? layoutIndex : 0, index);
    } else {
      handleLayoutChange(0, index);
    }
  }

  // Sets the lettering options back to its default empty state
  function resetLetteringOptions(indexes: { side: number; layout: number }) {
    setLetteringOptions({
      layout: options[optionKeys[indexes.side]][indexes.layout].id,
      color: "",
      font: "",
      text: {},
    });
  }

  // When a new layout is selected handle how to react and populate letteringOptions if data is already submitted there
  // index - the index of the selected layout.
  function handleLayoutChange(index: number, sideIndexChange: number) {
    let selectedData = currentLetteringValue[optionKeys[sideIndexChange]];
    setSelectedLayout(index);

    // If we have data for that side, and the selected layout is the layout with the data, populate the fields
    // Otherwise, reset the lettering options for a clean slate
    if (
      selectedData &&
      selectedData.layout === options[optionKeys[sideIndexChange]][index].id
    ) {
      setLetteringOptions(selectedData);
    } else {
      resetLetteringOptions({
        side: sideIndexChange || selectedSide,
        layout: index,
      });
    }
  }

  // Handle how to react when the user toggles the lettering section
  function handleLetteringToggle() {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    setSelectedLayout(0);
    setSelectedSide(0);
    resetLetteringOptions({ side: 0, layout: 0 });
    updateProduct({}, id);
  }

  function handleSubmit() {
    updateProduct(
      {
        ...currentLetteringValue,
        [optionKeys[selectedSide]]: letteringOptions,
      },
      id
    );
  }

  function handleClear() {
    resetLetteringOptions({ side: selectedSide, layout: selectedLayout });
    delete currentLetteringValue[optionKeys[selectedSide]];
    updateProduct(currentLetteringValue, id);
  }

  // Renders the options of a selected layout
  function renderSidePanel() {
    let letteringLayouts = options[optionKeys[selectedSide]][selectedLayout];
    let fieldNotSet =
      !currentLetteringValue[optionKeys[selectedSide]] ||
      currentLetteringValue[optionKeys[selectedSide]].layout !==
        letteringLayouts.id;
    console.log(letteringOptions);
    return (
      <Sidebar>
        <h3>Message{letteringLayouts.fields.length > 2 && "s"}</h3>
        <OptionsInputField>
          {letteringLayouts.fields.map((location: Layout) => {
            return (
              <React.Fragment key={optionKeys[selectedSide] + location.id}>
                <label htmlFor={location.id}>{location.name}</label>
                <input
                  value={letteringOptions.text[location.id] || ""}
                  disabled={!fieldNotSet}
                  required={true}
                  type="text"
                  key={
                    optionKeys[selectedSide] +
                    letteringOptions.layout +
                    location.id
                  }
                  id={location.id}
                  placeholder="Message here"
                  onChange={(e) => {
                    setLetteringOptions({
                      ...letteringOptions,
                      text: {
                        ...letteringOptions.text,
                        [location.id]: e.target.value,
                      },
                    });
                  }}
                />
              </React.Fragment>
            );
          })}
        </OptionsInputField>
        {letteringLayouts.fields.length > 0 && (
          <React.Fragment>
            <h3>Font Styles</h3>
            <OptionsInputField>
              <label htmlFor="font-face">Font Face</label>
              <CustomSelectInternal
                isRequired={true}
                isCompleted={letteringOptions.font.length > 0}
                disabled={!fieldNotSet}
                onChange={(id) => {
                  setLetteringOptions({ ...letteringOptions, font: id });
                }}
                value={letteringOptions.font}
                key={
                  optionKeys[selectedSide] + letteringOptions.layout + "font"
                }
                options={{
                  type: SelectTypes.TEXT,
                  data: fonts,
                  required: true,
                }}
              />
              <label htmlFor="font-color">Font Color</label>
              <CustomSelectInternal
                isRequired={true}
                isCompleted={letteringOptions.color.length > 0}
                disabled={!fieldNotSet}
                onChange={(id) => {
                  setLetteringOptions({ ...letteringOptions, color: id });
                }}
                value={letteringOptions.color}
                key={
                  optionKeys[selectedSide] + letteringOptions.layout + "color"
                }
                options={{
                  type: SelectTypes.COLOR,
                  data: colors,
                  required: true,
                }}
              />
            </OptionsInputField>
          </React.Fragment>
        )}
        {letteringLayouts.hasLogo && (
          <React.Fragment>
            <h3>Addons</h3>
            <OptionsInputField>
              <label>Logo</label>
              <CustomSelectInternal
                isRequired={true}
                isCompleted={
                  !!letteringOptions.logo && letteringOptions.logo.length > 0
                }
                disabled={!fieldNotSet}
                onChange={(id) => {
                  setLetteringOptions({ ...letteringOptions, logo: id });
                }}
                value={letteringOptions.logo || ""}
                key={
                  optionKeys[selectedSide] + letteringOptions.layout + "logo"
                }
                options={{ type: SelectTypes.IMG, data: logos, required: true }}
              />
            </OptionsInputField>
          </React.Fragment>
        )}
        {fieldNotSet ? (
          <Button type="submit">Add Lettering</Button>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClear();
            }}
            type="button"
          >
            Clear Lettering
          </Button>
        )}
      </Sidebar>
    );
  }

  useEffect(() => {
    if (!letteringOptions) {
      handleSideChange(0);
    }
  }, [handleSideChange, letteringOptions])

  if (!letteringOptions)
   return <div>Loading...</div>

  // Loop through each side and render the previews
  Object.keys(options).forEach((side, index) => {
    const layouts = options[side];
    // Render each top tab for selecting side
    renderedTops.push(
      <div key={side}>
        <input
          type="radio"
          name="side"
          required={true}
          id={side}
          checked={index === selectedSide}
          onClick={handleSideChange.bind(null, index)}
        />
        <label htmlFor={side}>
          <div>
            <h3>{side}</h3>
          </div>
        </label>
      </div>
    );

    // Rendered bottom for each layout and its preview image
    renderedBottoms.push(
      <React.Fragment key={side + "-layouts"}>
        {layouts[selectedLayout] ? (
          <PreviewWrapper>
            <img src={layouts[selectedLayout].img} alt="Lettering preview" />
          </PreviewWrapper>
        ) : (
          "Error with index: " + selectedLayout
        )}
        <BottomTabs>
          <div>
            <h3>Layout</h3>
          </div>
          {layouts.map((layout: LetteringLayout, layoutIndex: number) => {
            return (
              <div key={layout.id}>
                <input
                  type="radio"
                  name="layout"
                  id={layout.id}
                  disabled={!!currentLetteringValue[optionKeys[selectedSide]]}
                  checked={layoutIndex === selectedLayout}
                  onClick={() => {
                    handleLayoutChange(layoutIndex, selectedSide);
                  }}
                />
                <label htmlFor={layout.id}>
                  <div>
                    <h3>
                      {layout.name} <Price>- ${layout.price}</Price>
                    </h3>
                  </div>
                </label>
              </div>
            );
          })}
        </BottomTabs>
      </React.Fragment>
    );
  });

  return (
    <form
      name={id}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <H3>{name}</H3>
      <div>
        <p>
          Lettering will allow for sick customization, you can get custom text
          and logos.
        </p>
        <label>
          Show lettering options:
          <input
            type="checkbox"
            checked={isOpen}
            onChange={handleLetteringToggle}
          />
        </label>
      </div>
      {isOpen && (
        <CustomLetteringStyle>
          <LetteringPreview>
            <TopTabs>
              <div>
                <h3>Side</h3>
              </div>
              {renderedTops}
            </TopTabs>
            {renderedBottoms[selectedSide]}
          </LetteringPreview>
          {renderSidePanel()}
        </CustomLetteringStyle>
      )}
    </form>
  );
};

export default connector(CustomLetteringNew);
