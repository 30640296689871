import styled from "styled-components";
import { ThemeAccentColor } from "./basic";

export const AboutSection = styled.div`
  text-align: center;
  & > div {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  > div:first-child {
    @media only screen and (max-width: 850px) {
      display: block;
      width: 80%;
      margin: 20px 10%;
    }
    width: 60%;
    padding: 0 5%;
  }
  > div:last-child {
    @media only screen and (max-width: 850px) {
      display: block;
      width: 80%;
      margin: 20px 10%;
    }
    width: 40%;
  }
  p {
    @media only screen and (max-width: 850px) {
    }
    line-height: 28px;
  }
`;

export const CallToAction = styled.div`
  width: 70%;
  max-width: 800px;
  @media only screen and (max-width: 700px) {
    width: 100%;
    border-radius:0;
  }
  margin: 75px auto;
  background-color: ${ThemeAccentColor};
  border-radius: 5px;
  height: 350px;
  @media only screen and (max-width: 1200px) {
    height: auto;
  }
  color: white;
  text-align: center;
  padding: 50px 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  h1 {
    margin-bottom: 20px;
  }
  > div {
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
    > * {
      flex: 1;
    }
    > div {
      padding: 0 10% 0 5%;
      @media only screen and (max-width: 1200px) {
        padding: 15px 10%;
        width: 80%;
      }
    }
    p {
      @media only screen and (max-width: 1200px) {
        padding: 15px 5%;
      }
      padding: 0 20px;
      line-height: 28px;
    }
    input[type="submit"] {
      margin-top: 40px;
      padding: 10px 50px;
      color: black;
      font-size: 24px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.2, 1.2);
      }
    }
  }
`;

export const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 875px) {
    grid-template-columns: 1fr;
    > img {
      width: 400px;
      height: 400px;
    }
  }
  grid-gap: 50px;
  margin: 30px 0;
  justify-items: center;
  > img {
    @media only screen and (max-width: 875px) {
      width: 400px;
      height: 400px;
    }
    @media only screen and (max-width: 500px) {
      width: 80vw;
      height: 80vw;
    }
    width: 300px;
    height: 300px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.5, 1.5);
      @media only screen and (max-width: 875px) {
        transform: none;
      }
    }
  }
`;
