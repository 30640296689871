import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { PageWrapper } from "./assets/style/basic";
import Footer from "./components/footer";
import Header from "./components/header";
import About from "./pages/about";
import Contact from "./pages/contact";
import Events from "./pages/events";
import Confirm from "./pages/confirm";
import Home from "./pages/home";
import Shop from "./pages/shop";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Cart from "./components/cart";
import ProductPage from "./pages/product/ProductPage";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "./redux/store";
import { fetchProducts } from "./redux/actions/productActions";
import Banner from "./components/banner";

let connector = connect(
  (state: StoreState) => ({}),
  {fetchProducts}
);

type ReduxProps = ConnectedProps<typeof connector>;

const App: React.FC<ReduxProps> = ({fetchProducts}) => {
  const location = useLocation();
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts])

  return (
    <>
      <Cart />
      <Banner message="<strong>Please note:</strong> This website is in beta and isn't perfect. We are working on improving it, but if you experience any errors <a>please let us know here</a>. </br>Payment is handled by Square and is secure, <a>click here to learn more about how we process payments</a>."/>
      <Header />
      <PageWrapper>
        <ScrollToTop />
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            classNames="page-fade"
            key={location.key}
          >
            <Switch location={location}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route exact path="/products">
                <Shop />
              </Route>
              <Route exact path="/products/:id">
                <ProductPage />
              </Route>
              <Route exact path="/confirm">
                <Confirm />
              </Route>
              <Route path="/events">
                <Events />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default connector(App);
