import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import {
  Product,
  ProductValue,
  CartNotification,
  ProductDetails,
} from "../types";
import rootReducer from "./reducers/root";

export interface StoreState {
  products: Product[] | { details: ProductDetails }[];
  current: ProductValue;
  cart: ProductValue[];
  notifications: CartNotification[];
}

const initialState: StoreState = {
  products: [],
  current: { price: 0, id: "", values: {}, quantity: 0 },
  cart: [],
  notifications: [],
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
