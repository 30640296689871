import { animated } from "react-spring";
import styled from "styled-components";

export const CartClosed = styled(animated.div)`
  position: fixed;
  z-index: 500;
  right: 15px;
  top: 15px;
  > * {
    float: right;
    clear: both;
    position: relative;
  }
`;

export const CartButton = styled.button`
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: #66baa8;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const EmptyCart = styled.div`
  display: grid;
  align-self: center;
  text-align: center;
  justify-self: center;
  z-index:99;
  img {
    justify-self: center;
    width: 200px;
    height: 200px;
  }
`;

export const NotificationCard = styled(animated.div)`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 5px;
  padding: 10px 30px;
  margin: 10px 0;
  align-content: center;
  width: 300px;
  overflow: hidden;
  div.timer {
    background: rgb(102, 186, 168);
    height: 5px;
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    animation-duration: 10s;
    animation-name: load;
    animation-timing-function: linear;
  }
  @keyframes load {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }
  h3 {
    text-align: center;
  }
`;

export const CartBody = styled(animated.div)`
  position: fixed;
  height:100%;
  width:100%;
  left:0;
  top:0;
  z-index: 100;
  .cart-close {
    position: absolute;
    img {
      height: 50px;
      width: 50px;
    }
    left: 20px;
    top: 20px;
  }
  div.cartContent {
    position: absolute;
    height: 100%;
    background-color: white;
    max-width: 800px;
    width: 100%;
    right: 0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    padding: 30px 60px;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  h1 {
    text-align: center;
  }
  button {
    margin: 0 auto;
  }
  ul {
    overflow: auto;
    list-style: none;
    padding: 0;
  }
  .hidden-background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const CartCard = styled.div`
  display: grid;
  grid-template-areas: "a b c";
  grid-template-columns: 250px 2fr 1fr;
  grid-gap: 30px;
  @media only screen and (max-width: 675px) {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "a a" "b c";
    grid-gap: 10px;
    padding: 0;
    align-items: center;
    img {
      max-width: 300px;
    }
  }
  @media only screen and (max-width: 500px) {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
    padding: 0;
    align-items: center;
    img {
      max-width: 300px;
    }
    text-align: center;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  h2,
  h3 {
    margin: 0;
  }
  > div {
    margin-top: 20px;
  }
  div:first-child {
    grid-area: b;
  }
  div:last-child {
    grid-area: c;
  }
  img {
    grid-area: a;
    max-width: 100%;
    max-height: 200px;
    margin: 0 auto;
    border-radius: 5px;
  }
  button {
    margin: 0 10px 0 0;
  }
`;

export const CartCheckoutPage = styled.div`
  position: absolute;
  overflow: auto;
  max-height: 100%;
  width: 50%;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  padding: 30px;
  .ErrorText {
    color:red;
    text-align:center;
    font-style: italic;
  }
  @media only screen and (max-width: 1300px) {
    width: 80%;
    button.cart-close {
      right: 5px;
      left: unset;
      top: 5px;
    }
  }
  @media only screen and (max-width: 550px),
    only screen and (max-height: 500px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
  }
  button#submit {
    margin: 30px calc(50% - 100px) 0;
    width: 200px;
  }
  form {
    > div {
      display: grid;
      grid-column-gap: 50px;
      grid-row-gap: 20px;
    }
    .error input{
      border-color:red;
    }
    #personal {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "a b" "c c" "d d";
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-areas: "a" "b" "c" "d";
      }
      #fName {
        grid-area: a;
      }
      #lName {
        grid-area: b;
      }
      #email {
        grid-area: c;
      }
      #tel {
        grid-area: d;
      }
    }
    #shipping {
      @media only screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "a a" "b b" "c d";
      }
      @media only screen and (max-width: 400px) {
        grid-template-columns: 1fr;
        grid-template-areas: "a" "b" "c" "d";
      }
      grid-template-columns: 3fr 1fr 1fr;
      grid-template-areas: "a a a" "b c d";
      #address {
        grid-area: a;
      }
      #city {
        grid-area: b;
      }
      #state {
        grid-area: c;
      }
      #postal {
        grid-area: d;
      }
    }
    label {
      input,
      select {
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        display: block;
        border: 1px solid black;
        outline: none;
      }
      select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
      }
    }
  }
`;
